import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tabs.css';

export default () => (
    <Tabs>
        <TabList>
            <Tab>V1: Spark Era: ORIGIN</Tab>
            <Tab>V2: Spark Era: PROOF OF LEGEND</Tab>
            <Tab>V3: Spark Era: ETERNAL GALAXY</Tab>
        </TabList>

        <TabPanel>
            <h2>Any content 1</h2>
        </TabPanel>
        <TabPanel>
            <h2>Any content 2</h2>
        </TabPanel>
        <TabPanel>
            <h2>Any content 3</h2>
        </TabPanel>
    </Tabs>
);
