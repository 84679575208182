const aritcleList = [
    {
        title: ` <h2 style="text-transform: capitalize;">
        Firework games is a practitioner of an open and inclusive diverse game world
      </h2>`,
        time: `<span style="display: inline-block;
        margin-bottom: 30px;
        font-size: 12px; text-transform: capitalize;">published on december 21, 2021</span>`,
        detail: `<div>
        <img src="/image/news/01.png" style="margin: 15px 10vw; max-width: 50vw" />
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        Firework Games is a decentralized self-developed launching platform of blockchain games,aims to provide feasible ways for gamers worldwide to participate in blockchain games,as well as developing a bridging interface from traditional games to blockchain games.
        </p>
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        Our visions are to connect and lower the difficulty for beginner developers to start their journey in blockchain games,and to offer assistance for the transition of traditional game industry to evolve into blockchain based games. Firework Games offers gamers worldwide to enjoy our game while accumulating real world value.The in-game assets of all the games developed by Firework Games will be delivered to the gamers as Non-fungible Token(NFT) assets.Gamers can fully control the assets they purchase and reap the real benefits by extracting the virtual in-game revenue into the real revenue.
        </p>
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        Firework Games focuses its development on blockchain protocol.With the constant improvement of blockchain as well as the gaming development technologies,there are numerous innovative possibilities to explore. Blockchain allows swift trading speed at low cost, safe and convenient to operate and to play the game. In addition,Fireworks Games aims to utilize decentralization and full transparency of blockchain protocol provides. Combine these factors, it is without a doubt that we can establish a perfect gaming platform.
        </p>
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        Firework Games is the most advanced and forward-looking blockchain game platform.We wish to provide an open and inclusive diverse game world based on the blockchain technology, which will dramatically transform the gaming industry.Anyone is allowed to create,to play and to enjoy his own role in the parallel world.”— Radesh, Firework Games Co-founder and CEO
        </p>
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        Based in Hong Kong,the team composes of members from Singapore,China, America and Japan.Since 2010, our team has been engaged in gaming development.Our production staffs have been involved in the international top tier game companies for over ten years,such as Blizzard Entertainment, Riot Games,Tencent Games and Epic Games,and involved in the development of 10+ AAA games,such as Eve Online,StarCraft,League of Legends,Ring of Elysium,NARAKA：Bladepoint.To attract the best game developers around the world,apart from a physical office,we also advocate decentralized and distributed office worldwide,ensuring the suitable office environment for our stuffs.Our goal is not just to be another blockchain game developer,but to transform the currently gaming industry into its next era.
        </p>
        <img src="/image/news/02.png" style="margin: 15px 10vw; max-width: 50vw" />
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        “Game communitization embodies the sharing concept of blockchain mechanics. The game ecology of global players is becoming diversified,community-oriented and open.Firework Games provide a fair,free,open and innovative platform for game lovers around the world.Through the advanced blockchain technology,we will create valuable on-chain games,and optimize the gaming experience of global players by further constructing community.In the future,Firework Games will not only provide first-class game content but also add developer options to attract more independent game enthusiasts to join and develop more excellent games. And these games will be voted by all players from the Firework Games community.” — James Zhao, Firework Games Co-founder and CSO
        </p>
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        Firework Games Developers Foundation is an innovative concept for gaming community.During the process of game development in the past decade,we have constantly observed that there are many creative independent game enthusiasts who are unable to freely publish their game works,or are limited by financial pressure or policy pressure.Thanks to the transparent and fast blockchain technology,Firework Games will continue to invest more money and energy in the game field in the future.We call on all indie game enthusiasts worldwide to develop their own games and upload them to Firework Games.All community members will have demo votes,which is fair and transparent,thus providing every developer with a realistic and feasible revenue model and making it possible for all community members to participate in the Decentralized Autonomous Organization(DAO) model.
        </p>

        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        “Get back to gaming” is our slogan,also the initial intention at the beginning of founding Firework Games — to create and relish.When we observe that many games in the market are just financial products in the name of games,not strictly game-like,we started to reflect.In the long term,any financial product is risky,especially the blockchain financial products at this stage.It is hard to guarantee that the interests of player will not be damaged due to the trading trend of the market.When the interests of player are damaged and financial loopholes appear,such financial products in the name of games will collapse.
        </p>
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        “The game created by Firework Games enjoys both unique economic model of a blockchain game and the exciting gameplay of a traditional game.The economic model will make the distinctive economic benefits for gamers while the wonderful gameplay provides guarantee for the vitality and endurance of the game products.The game in the future will be jointly maintained and constantly updated by developers and community players,so as to continue the vitality of the game.” — Yurion,Firework Games Co-founder and Artistic Director Firework Games is a pioneer of an open and inclusive diverse game world. We expect to run into you,gamers,and enjoy playing the game together,creating a brand-new exciting gaming era.
        </p>
        </div>`,
    },
    {
        title: ` <h2 style="text-transform: capitalize;">
        The First Polygon-grant Global Hackathon Competition Came To An End, And Spark Era Won The 8th Place In The World!
      </h2>`,
        time: `<span style="display: inline-block;
        margin-bottom: 30px;
        font-size: 12px; text-transform: capitalize;">Published On December 11, 2021</span>`,
        detail: `<div>
        <img src="/image/news/03.png" style="margin: 15px 10vw; max-width: 50vw" />
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        Spark Era is a massively multiplayer online role-playing game (MMORPG) independently developed by Firework Games and based on interstellar backgrounds, in which players can enter a huge interstellar world and explore new planets in it, construct game ecology and play various planetary resident roles for faction confrontation. Based on block chain technology for development and Unity engine for game production, it supports global players in real-time meta-universe battles, and supports multi-chain shared game ecology and cross-chain asset transfer, etc. As a popular interstellar-background and multi-chain-supporting block chain game, it is rare in the current market, so Spark Era has achieved very good ranking in the Polygon-Grant Global Hackathon Competition with its creative art design style and unique dual-token economic concept, and has won high praise from users.
        </p>
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        Polygon, formerly known as Matic Network, plans to become the first Layer 2 solution aggregator on the Ethereum chain, and build a modular, universal, and flexible scaling framework for Ethereum. Polygon is defined as one of the most developer-friendly ecosystems outside of the Ethereum main chain. Its ecosystem has been specifically designed to make it easier for developers to use polygons to create web 3.0 applications as well as access a user base of over 1 million and successfully attract Venture Capital for sponsorship funding.
        </p>
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        The much-anticipated first Polygon-Grants hackathon kicked off on Hacklink.io, DoraHacks' open source developer incentive platform, with a $100,000 prize pool. In the first Polygon-Grant global hackathon competition, it not only attracted the active participation of developers from all over the world, but also attracted the attention of many investors.
        </p>
        <p
          style="
              color: #fff;
              font-size: 16px;
              margin-bottom: 30px;
          "
        >
        In the first Polygon-Grant global hackathon competition, Spark Era stood out from hundreds of entrants from all over the world, defeated many project development leaders, and won the outstanding achievament of the eighth place in the world and the fifth place in the prize pool! At the same time, in the Global Hackathon Competition the Spark Era project has not only been recognized and favored by well-known investors in the industry, we also received 2896.6 MATIC tokens invested in the project by the community and 2451.08 MATIC tokens. from the user's support for the project.
        </p>
        <img src="/image/news/04.png" style="margin: 15px 10vw; max-width: 50vw" />
        <p
        style="
            color: #fff;
            font-size: 16px;
            margin-bottom: 30px;
        "
      >
      As the popularity of the project continues to grow, Spark Era’s Loot for Spark Era (LSE) system has been enthusiastically sought after by fans as soon as it was launched! Players mint 49,000 LSE assets in just 50 minutes, which immediately caused serious congestion of Polygon's transaction network, and also caused a large backlog of unprocessed transaction feedback!
      </p>
      <p
      style="
          color: #fff;
          font-size: 16px;
          margin-bottom: 30px;
      "
    >In addition, Spark Era's LSE assets have no doubt ranked first in the 24-hour polygon network transaction ranking, continued to occupy the 6th place in the global transaction ranking list for a week in a row, and remained very stable at the 36th place in the 30-day global transaction list!</p>
        <p style="
        color: #FFC300;
        font-size: 16px;
        margin-bottom: 30px;
    "
  >
  Spark Era leads the chain game into a new chain era with its ground-breaking block-chain economic model, free-to-play, cross-chain and unique play-to-earn gameplay.</p>


        </div>`,
    },
    {
        title: ` <h2 style="text-transform: capitalize;">
        Rising Star! Review Of Spark Era Opening A New Ear Of Gaming At Opensea.
</h2>`,
        time: `<span style="display: inline-block;
        margin-bottom: 30px;
        font-size: 12px; text-transform: capitalize;">Published On December 21, 2021</span>`,
        detail: `<div>
  <p
    style="
        color: #fff;
        font-size: 16px;
        margin-bottom: 30px;
    "
  >
      Spark Era is a massively multiplayer online role-playing game (MMORPG)
      independently developed by Firework Games and based on an interstellar
      background. Developed based on the block-chain technology, the game
      ecological construction is promoted with GameFi and NFT game sovereign
      assets as the core. As a block chain-based meta-universe large-scale game,
      with its pioneering economy and governance system and unique art style,
      Spark Era has achieved outstanding results in the Opensea NFT trading
      market!
  </p>

  <p
      style="
          color: '#fff';
          font-size: 16px;
          margin-bottom: 30px;
      "
  >
      Spark Era's Loot for Spark Era (LSE) system was very enthusiastically sought
      after by users as soon as it was launched!
  </p>

  <p
      style="
          color: #FFC300;
          font-size: 16px;
      "
  >
      Players mint 49,000 LSE assets with only 50 minutes, causing instantaneous
      congestion of the POLYGON trading network!
  </p>
  <p
      style="
          color: #FFC300;
          font-size: 16px;
      "
  >
      The trading of LSE assets on Opensea has also achieved excellent results,
      ranking first in the 24-hour polygon network transaction list! The 7-day
      yield was as high as 720%!
  </p>
  <p
      style="
          color: #FFC300;
          font-size: 16px;
          margin-bottom: 30px;
      "
  >
      Won the 6th place in the highest seven-day trading list! And stably remained
      at the 36th place in the 30-day trading list!
  </p>

  <p
      style="
          color: '#FFF';
          font-size: 16px;
      "
  >
      In order to implement the advanced game mode of free-to-play, Spark Era is
      giving players
      <span
          style="
              color: #FFC300;
              font-size: 16px;
          "
      >
          over 19,900 loot boxes for free
      </span>
      in the Epic Giveaway of Spark Era event, with a cumulative value of
      <span
          style="
              color: #FFC300;
              font-size: 16px;
          "
      >
          more than 100,000 US dollars!
      </span>
  </p>
  <p
      style="
          color: '#FFF';
          font-size: 16px;
          margin-bottom: 30px;
      "
  >
      And allow players to trade on Opensea. EGSE assets trade on Opensea has also
      achieved excellent results!
  </p>
  <p
      style="
          color: '#FFF';
          font-size: 16px;
      "
  >
      Spark Era is known for its groundbreaking block chain economic model,
      play-and-earn design, GameFi function, and high liquidity of NFT assets, and
      its successful LSE assets bring power to players who join Spark Era. These
      LSE assets will redeem for various tokens of Spark Era and exchange for
      in-game assets. It enables players to create tangible value by exploring,
      deploying political strategies, and influencing the future of meta-universe
      development.
  </p>
  <p
      style="
          color: '#FFF';
          font-size: 16px;
          margin-bottom: 30px;
      "
  >
      After we announced the new LSE asset points program, the total transaction
      volume of LSE assets exceeded 13ETH, a record high. And keep climbing!
  </p>

  <p
      style="
          color: '#FFF';
          font-size: 16px;
      "
  >
      With the arrival of new PSE assets, it has been sought after by a large
      number of players to actively exchange for PSE assets. So far, the trading
      of PSE assets on Opensea has also achieved excellent results! The total
      trading volume of PSE assets has exceeded 26.3ETH!
  </p>
  <p
      style="
          color: #FFC300;
          font-size: 16px;
      "
  >
      Ranked first in the 24-hour polygon network transaction list! The 7-day
      yield was as high as 2300%!
  </p>
  <p
      style="
          color: #FFC300;
          font-size: 16px;
          margin-bottom: 30px;
      "
  >
      Won the 3rd place in the highest seven-day trading list! And stable at No.
      20 in the 30-day trading list!
  </p>
  <p
      style="
          color: '#FFF';
          font-size: 16px;
      "
  >
      The sale of Spark Era assets proves the limitless possibilities of block
      chain-based gaming. We're very pleased with our progress so far, and this
      sale will help drive our further development of the Spark Era game. We haven
      given a new definition to the popular activity of Loot, so that the player's
      mint brings real benefits, not just a picture. Spark Era fans are working
      hard to build a meta-universe world of the next-generation socio-economic
      paradigm!
  </p>
</div>`,
    },
    {
        title: ` <h2 style="text-transform: capitalize;">Cross-chain Conversion Will Be Available For Pse Assets!</h2>`,
        time: `<span style="display: inline-block;
    margin-bottom: 30px;
    font-size: 12px; text-transform: capitalize;">Published On January 11, 2022</span>`,
        detail: `<div>
        <p
            style="
                color: #fff;
                font-size: 16px;
                margin-bottom: 30px;
            "
        >
        Spark Era is now providing every player who has participated in LSE (Loot for Spark Era) with free LSE assets which could be converted to PSE assets in future activities. PSE assets will allow players to conduct conversion of game spaceship. (This activity is over now)

        </p>

        <p
            style="
                color: '#fff';
                font-size: 16px;
                margin-bottom: 30px;
            "
        >

        -An accumulative 49,000 players own LSE assets; over 47,000 players will be given a spaceship for free. 202 out-of-print souvenir spaceships will be provided as gift.

        </p>

        <p
            style="
                color: #FFF;
                font-size: 16px;
            "
        >

        *Spark Era will provide NFT assets cross-chain function, offering the players with free NFT assets transfer from Opensea.

        </p>
        <p
            style="
                color: #FFF;
                font-size: 16px;
            "
        >

        * LSE assets: LSE assets is unable to conduct NFT assets cross chain conversation. It will become history and stay in the Opensea souvenir shop forever.

        </p><p
        style="
            color: #FFF;
            font-size: 16px;
        "
    >


*EGS assets: EGSE assets could conduct NFT assets cross chain conversion. Spark Era is the art resources of EGse on Opensea with most latest upgrade.

    </p>
    <div>
    <h3 style="
    color: #FFF;
    font-size: 22px;
">
Brand New Assets Appearance:

</h3>
<div style="display: flex;
align-items: center;
// transform: scale(.7);
margin: 30px auto;">
<div style="text-align: center">
    <img src="/image/news/box.png" width="200" alt="" />
    <p style="font-size: 12px">EPIC GIVEAWAY OF Spark Era NORMAL</p>
</div>
<div style="text-align: center">
    <img src="/image/news/box2.png" width="200" alt="" />
    <p style="font-size: 12px">EPIC GIVEAWAY OF Spark Era RARE</p>
</div>
<div style="text-align: center">
    <img src="/image/news/box3.png" width="200" alt="" />
    <p style="font-size: 12px">EPIC GIVEAWAY OF Spark Era EPIC</p>
</div>
</div>
    </div>
    <div>
    <p style="
    color: #FFF;
    font-size: 16px;
">*PSE assets: PSE assets could conduct NFT assets cross chain conversion. Spark Era is the art resources of PSE on Opensea with most latest upgrade.</p>
    </div>
    <div style="display: flex;
    align-items: center;
    // transform: scale(.7);
    margin: 30px auto;">
    <div style="text-align: center;margin-right: 60px;">
        <img src="/image/news/Passport_Star.png" width="100" alt="" />
        <p style="font-size: 14px">PASSPORT START</p>
    </div>
    <div style="text-align: center;margin-right: 60px;">
        <img src="/image/news/Passport_Galxy.png" width="100" alt="" />
        <p style="font-size: 14px">PASSPORT START</p>
    </div>
    <div style="text-align: center;margin-right: 20px;">
        <img src="/image/news/Passport_Eternal.png" width="100" alt="" />
        <p style="font-size: 14px">PASSPORT ETERNAL</p>
    </div>
    </div>
    <div>
    <p style="
    color: #FFF;
    font-size: 16px;
">*Trailblazer: Trailblazer could conduct NFT assets cross chain conversion. Spark Era is the art resources of PSE on Opensea with most latest upgrade.</p>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-around;
    ">
      <div>
          <img src="/image/news/2.png" width="300" alt="" />
      </div>
      <div>
          <img src="/image/news/486.png" width="40" alt="" />
      </div>
      <div>
          <img src="/image/news/Gamma.png" width="300" alt="" />
      </div>
  </div>
</div>`,
    },
];

export default aritcleList;
