import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import Contract from '../../components/Contract';
import newList from './newList';
import { isMobile } from '../../utils';
import styles from './index.module.scss';

const NewsList = props => {
    return (
        <div className={styles.NewsList}>
            <Nav />

            <div className={styles.gameNewsImg}>
                <p>FIREWORK GAMES NEWS</p>
            </div>

            <div className={styles.title}>
                <h2>LATEST NEWS</h2>

                <ul>
                    {newList.map(item => {
                        return (
                            <li>
                                <div className={styles.content}>
                                    <Link to={item.detailUrl}>
                                        <img
                                            src={item.img}
                                            width={isMobile() ? '350' : '400'}
                                            alt=""
                                        />
                                    </Link>

                                    <div className={styles.contentDetail}>
                                        {!isMobile() && <p className={styles.date}>{item.date}</p>}
                                        <h3>{item.title}</h3>
                                        {!isMobile() && (
                                            <>
                                                <div className={styles.contents}>
                                                    {item.content}
                                                </div>
                                                <Link
                                                    to={item.detailUrl}
                                                    className={styles.details}
                                                >
                                                    See More
                                                </Link>
                                            </>
                                        )}
                                        {isMobile() && <p className={styles.date}>{item.date}</p>}
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <Contract />
            </div>
        </div>
    );
};

export default NewsList;
