import Nav from '../../components/Nav';
import styles from './page.module.scss';
import appBg1 from '../../assets/imgs/stake/page-bg1.png';
import appBg2 from '../../assets/imgs/stake/page-bg2.png';
import appBg3 from '../../assets/imgs/stake/page-bg3.png';
import panelBg1 from '../../assets/imgs/stake/page-panel-bg1.png';
import panelBg2 from '../../assets/imgs/stake/page-panel-bg2.png';
import panelBg3 from '../../assets/imgs/stake/page-panel-bg3.png';
import nftImage1 from '../../assets/imgs/stake/Standard-loot-box.png';
import FIRE_png from '../../assets/imgs/stake/FIRE.png';
import iconXGreen from '../../assets/imgs/stake/icon-x-green.png';
import standardLootBox from '../../assets/imgs/stake/Standard-loot-box.png';
import epicLootBox from '../../assets/imgs/stake/Epic-loot-box.png';
import legendaryLootBox from '../../assets/imgs/stake/Legendary-loot-box.png';
import ulIcon from '../../assets/imgs/stake/ul-icon.png';
import { useState } from 'react';
import { useEffect } from 'react';
// import Web3 from 'Web3';
const Web3 = window.Web3;
import IStake from '../../contract/IStake.json';
import { initWallet } from './service';
import fire from '../../contract/fire.json';
const queryString = require('query-string');
import Spin from '../../components/Spin';
import { accountIsSameAsCurrentWallet, devLog, formatDate, formatNumber } from '../../utils';
import phaseBg from '../../assets/imgs/stake/phase-bg.png';
import tipsIcon from '../../assets/imgs/stake/tips-icon.png';

import { Tooltip } from 'antd';
import 'antd/es/tooltip/style/css';
import modalBg from '../../assets/imgs/stake/modal-bg.png';
import { message } from 'antd';
import 'antd/es/message/style/css';
import { GoogleAnalyticsPurchase } from 'firework-analytics';
import { useHistory } from 'react-router-dom';
import { useRef } from 'react';

// 合约地址
const stakeAddress = process.env.REACT_APP_STAKE;
const ERC20fireAddress = process.env.REACT_APP_ERC20_FIRE;
const ERC20quarkAddress = process.env.REACT_APP_ERC20_QUARK;

const Index = () => {
    const history = useHistory();
    // 初始化合约
    let contractIStake,
        // bcTime = 0,
        _web3;
    if (window.web3 !== undefined) {
        _web3 = new Web3(window.web3.currentProvider);
        // 创建质押合约
        const contract = new _web3.eth.Contract(IStake, stakeAddress);
        contractIStake = contract;

        // test
        // contractIStake.methods
        //     .getTotalPoolSupply()
        //     .call()
        //     .then(res => {
        //         console.log(`目前共有矿池1-` + res);
        //     });
    }

    // 常用方法
    const toBn = n => _web3.utils.toBN(n);
    const toWei = n => _web3.utils.toWei(toBn(n), 'ether');
    const fromWei = n => _web3.utils.fromWei(toBn(n), 'ether');
    // const formatDate = date => (date ? new Date(date * 1000).toLocaleString() : '-');

    const ssUserInfo = sessionStorage.getItem('userInfo');
    const userInfo = (ssUserInfo && JSON.parse(ssUserInfo)) || {};
    // useEffect(() => {
    //     initWallet();
    // }, []);

    const urlPramas = queryString.parse(location.search);
    const currentPoolId = urlPramas.type;
    const [modalState, setModalState] = useState({
        visibale: false,
        title: 'title',
        message: 'message',
    });

    // onConfirm: () => {},
    // const [onConfirm, setOnConfirm] = useState(() => {});
    let onClose = useRef(null);
    const showModal = (
        title = modalState.title,
        message = modalState.message,
        callback = () => {},
    ) => {
        setModalState({
            visibale: true,
            title,
            message,
        });
        onClose.current = callback;
        const elem = document.getElementById('modal');
        elem.querySelector('div').style.display = 'flex';
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        setTimeout(() => {
            elem.style['backdrop-filter'] = 'blur(10px)';
        }, 0);
    };
    const hideModal = () => {
        const elem = document.getElementById('modal');
        elem.style['backdrop-filter'] = '';
        elem.querySelector('div').style.display = 'none';
        document.getElementsByTagName('body')[0].style.overflow = '';
        setTimeout(() => {
            setModalState({
                ...modalState,
                visibale: false,
            });
            if (typeof onClose.current === 'function') {
                onClose.current();
            }
        }, 300);
    };

    // const onClose = () => {};

    // const get
    // useEffect(() => {}, []);

    const [inputValue, setInputValue] = useState('');
    const [poolInfo, setPoolInfo] = useState({
        poolId: '',
        beginVaultTime: '',
        endVaultTime: '',
        endStakingTime: '',
        // rewardNftType
        // 0 初始化，1 存入等待，2 代币存入中，3 质押中，4 质押结束提币中，5 质押池关闭
        status: '',
        takerThreshold: '',
        // 待领取的NFT数量
        nftNumber: '',
        // 已质押的代币数量
        stakingNumber: '',
        // 矿池nft奖励最大数量
        rewardNftLimit: 1,
        rewardNftAllocNumber: 0,
        rewardNftMaxSupply: 500,
    });
    const [leftdeposit, setLeftdeposit] = useState(0);
    const [willGetNftNumber, setWillGetNftNumber] = useState(0);
    const fetchData = async () => {
        // const account = localStorage.getItem('account');
        // const accountEmail = localStorage.getItem('accountEmail');
        const account = userInfo.WalletAddress;
        const accountEmail = userInfo.UserName;
        // todo 这里的判断逻辑可以优化
        if (!account) {
            devLog('account is null');
            // todo 从第三方登录的用户没有邮箱
            if (accountEmail) {
                showModal('Tips', 'You need to bind the wallet account, please bind first', () => {
                    history.push('/register');
                });
            } else {
                showModal('Tips', 'Please login or register an account', () => {
                    history.push('/register');
                });
            }
            return;
        }
        // const data = await fetch('https://yourapi.com');
        const resPoolInfo = await contractIStake.methods.queryPoolInfo(currentPoolId).call();
        // console.log('queryPoolInfo', resPoolInfo);
        const resUserStake = await contractIStake.methods
            .queryUserStake(currentPoolId)
            .call({ from: account });
        // console.log('queryUserStake', resUserStake);
        // 获取NFT奖励的要求
        const takerThreshold = fromWei(resPoolInfo['takerThreshold']);
        // 已获取的NFT数量
        const stakingNumber = fromWei(resUserStake['stakingNumber']);
        // 目前已经完成分配的NFT数量
        const rewardNftAllocNumber = resPoolInfo['rewardNftAllocNumber'] - 0;
        // 该矿池最大的NFT数量
        const rewardNftMaxSupply = resPoolInfo['rewardNftMaxSupply'] - 0;
        setPoolInfo({
            ...poolInfo,
            poolId: resPoolInfo['poolId'],
            beginVaultTime: resPoolInfo['beginVaultTime'],
            endVaultTime: resPoolInfo['endVaultTime'],
            endStakingTime: resPoolInfo['endStakingTime'],
            status: resPoolInfo['status'],
            takerThreshold,
            nftNumber: resUserStake['nftNumber'],
            stakingNumber,
            totalStakingTokens: fromWei(resPoolInfo['totalStakingTokens']),
            rewardNftAllocNumber,
            rewardNftMaxSupply,
        });
        setLeftdeposit(takerThreshold - stakingNumber);
    };
    useEffect(() => {
        // console.log('useEffect');
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const handleStake = async () => {
        try {
            setLoadingSubmit(true);
            const amount = inputValue - 0;

            // 判断是否有钱包地址
            const currentAddress = userInfo.WalletAddress;
            const accountEmail = userInfo.UserName;
            if (!currentAddress) {
                devLog('account is null');
                if (accountEmail) {
                    showModal(
                        'Tips',
                        'You need to bind the wallet account, please bind first',
                        () => {
                            history.push('/register');
                        },
                    );
                } else {
                    showModal('Tips', 'Please login or register an account', () => {
                        history.push('/register');
                    });
                }
                return;
            }

            // 判断是否为当前钱包链接地址
            const isSame = await accountIsSameAsCurrentWallet();
            if (!isSame) {
                message.error(
                    'The wallet address bound by the current user is different from the connected wallet address. The request is aborted.',
                );
                return;
            }
            /**
             *  check list
             * 1. 判断质押池是否在可存入期间
             * 2. 判断用户账户余额
             * 3. 查询授权金额
             * 4. 存入质押
             *
             */

            // 输入金额是否合法
            if (!/^[1-9]\d*$/.test(amount)) {
                showModal('Invalid number', 'Please enter the valid number.');
                return;
            }

            // 获取区块链时间
            const bcTime = await contractIStake.methods.getBlockchainTime().call();
            // console.log('bcTime', bcTime);

            //  1. 判断质押池是否在可deposit期间
            const poolInfos = await contractIStake.methods.queryPoolInfo(currentPoolId).call();
            // console.log('poolInfos', poolInfos);

            if (poolInfos['beginVaultTime'] > bcTime || bcTime > poolInfos['endVaultTime']) {
                // 隐式转换，问题不大
                console.log('server time', formatDate(bcTime));
                console.log('start deposit time', formatDate(poolInfos['beginVaultTime']));
                console.log('end deposit time', formatDate(poolInfos['endVaultTime']));
                showModal('Fail', 'This pool  is not open right now');
                return;
            }

            // 2. 判断用户账户余额
            var tfreNum = new _web3.eth.Contract(fire, ERC20fireAddress);
            const balance = await tfreNum.methods.balanceOf(currentAddress).call();
            console.log(`balance of FIRE:${fromWei(balance)}`);
            if (fromWei(balance) < amount) {
                showModal('Insufficient banalce', 'Insufficient balance');
                return;
            }

            //  3. 查询erc20 授权金额
            var tqukNum = new _web3.eth.Contract(fire, ERC20fireAddress);
            const amountFire = await tqukNum.methods.allowance(currentAddress, stakeAddress).call();
            console.log(`Approval fire:${fromWei(amountFire)},amout:${amount}`);
            // 如果当前授权金额小于存入金额
            if (toBn(amountFire).lt(toWei(amount))) {
                // 重新授权
                console.log('Approve Fire');
                await tfreNum.methods
                    .approve(stakeAddress, toWei(amount))
                    .send({ from: currentAddress });
            }

            const res = await contractIStake.methods.deposit(currentPoolId, toWei(amount)).send({
                from: currentAddress,
            });

            // TODO: 支付成功后调用Google Analytics
            // console.log('deposit', res);
            GoogleAnalyticsPurchase(
                amount,
                'FIRE',
                queryString.parse(window.location.search).poolId,
                [
                    {
                        item_name: `质押${queryString.parse(window.location.search).poolId}`,
                        item_id: currentPoolId,
                        affiliation: 'marketplace',
                    },
                ],
            );

            const resUserStake = await contractIStake.methods
                .queryUserStake(currentPoolId)
                .call({ from: currentAddress });
            // console.log('queryUserStake', resUserStake);
            const stakingNumber = fromWei(resUserStake['stakingNumber']);
            const nftNumber = resUserStake['nftNumber'];
            // showModal('Deposit success', 'Amout' + stakingNumber);
            showModal(
                'Successfully Deposited',
                <div className={styles.gourp}>
                    <div className={styles.left}>
                        <div className={styles.label}>$FIRE Deposit</div>
                        <div className={styles.text}>{formatNumber(stakingNumber)}</div>
                    </div>
                    {/* todo 改为动态获取nft奖励 */}
                    <div className={styles.divider}></div>
                    <div className={styles.right}>
                        <div className={styles.label}>The loot box you will receive</div>
                        <div className={styles.text}>
                            <img className={styles.nftImage} src={rewardsImage} alt={nftName} />×
                            {nftNumber}
                        </div>
                    </div>
                </div>,
            );
            fetchData();
            setInputValue('');
        } catch (error) {
            console.warn('handleStake error', error);
            if (error.message) {
                showModal('Error', error.message);
            }
        } finally {
            setLoadingSubmit(false);
        }
    };

    // useEffect(() => {
    //     console.log(poolInfo.takerThreshold);
    //     console.log(poolInfo.stakingNumber);
    //     setLeftdeposit(poolInfo.takerThreshold - poolInfo.stakingNumber);
    // }, [poolInfo.takerThreshold, poolInfo.stakingNumber]);

    // 计算目前存入金额可获得的NFT数量
    useEffect(() => {
        if (!/^[1-9]\d*$/.test(inputValue)) {
            setWillGetNftNumber(0);
            return;
        }
        // 已获取nft数量 - (已存入的金额+输入的金额)/每分配额需要的金额
        const nftNumber = poolInfo.nftNumber - 0;
        const stakingNumber = poolInfo.stakingNumber - 0;
        const takerThreshold = poolInfo.takerThreshold - 0;
        const rewardNftLimit = poolInfo.rewardNftLimit - 0;
        const input = inputValue ? inputValue - 0 : 0;
        // console.log('input', input);
        devLog('已获取nft数量', '已存入的金额', '输入的金额', '每分配额需要的金额');
        devLog(nftNumber, stakingNumber, input, takerThreshold);
        // 玩家可获取的nft总数
        let n = (stakingNumber + input) / takerThreshold;
        devLog('玩家可获取的nft总数', n);
        // 质押池可获取nft总数
        n = n > rewardNftLimit ? rewardNftLimit : n;
        devLog('质押池可获取nft总数', n);
        // 将要获的nft数量
        if (n) {
            n = n - nftNumber;
        }
        devLog('将要获的nft数量', n);
        // 向下取整
        n = ~~n;
        devLog('向下取整', n);
        if (poolInfo.rewardNftAllocNumber >= poolInfo.rewardNftMaxSupply) {
            // 奖励已被领取完
            setWillGetNftNumber(0);
        } else {
            setWillGetNftNumber(n);
        }
    }, [
        inputValue,
        poolInfo.nftNumber,
        poolInfo.stakingNumber,
        poolInfo.takerThreshold,
        poolInfo.rewardNftLimit,
        poolInfo.rewardNftAllocNumber,
        poolInfo.rewardNftMaxSupply,
    ]);

    let appBg, nftDesc, rewardsImage, poolName, panelBg, nftName;
    let tooltip1 = `According to the number of tokens you have deposited this time, it will be dynamically
        displayed the number of loot boxes Available.`,
        tooltip2 = `The total loot boxes you have earned in this pool.`;

    switch (currentPoolId) {
        case '1':
            appBg = appBg1;
            nftName = 'Premium Loot Box';
            nftDesc = `Premium loot box is a common loot box that contains NFT hero cards of all materials and
            rarities. There is a certain probability of dropping gold, silver and other special
            material cards.`;
            rewardsImage = standardLootBox;
            poolName = 'Standard Pool';
            panelBg = panelBg1;
            break;
        case '2':
            appBg = appBg2;
            nftName = 'Epic Loot Box';
            nftDesc = `Epic loot box is a precious loot box that contains NFT hero cards of all
            materials and rarities. There is a high probability of dropping gold, silver
            and other special material cards.`;
            rewardsImage = epicLootBox;
            poolName = 'Epic Pool';
            panelBg = panelBg2;
            break;
        case '3':
            appBg = appBg3;
            nftName = 'Legendary Loot Box';
            nftDesc = `Legendary loot box is an extremely valuable loot boxes with limited supply that contains
            NFT hero cards of all materials and rarities. There is a very high probability of
            dropping gold, silver and other special material cards.`;
            rewardsImage = legendaryLootBox;
            poolName = 'Lengendary Pool';
            panelBg = panelBg3;

            break;
        default:
            break;
    }
    const tips = [
        <span>
            Deposit time:{' '}
            <span className={styles.colorText}>
                {formatDate(poolInfo.beginVaultTime)} -- {formatDate(poolInfo.endVaultTime)}
            </span>
        </span>,
        `${poolName} require deposit at least ${formatNumber(poolInfo.takerThreshold)} Fire,
         when you deposit ${formatNumber(poolInfo.takerThreshold)} FIRE can get a loot box.`,
        `An address can get ${
            poolInfo.rewardNftLimit === 1 ? 'only one' : poolInfo.rewardNftLimit
        } loot box in this pool.`,
        <span>
            Deposit reward extraction time:{' '}
            <span className={styles.colorText}>{formatDate(poolInfo.endStakingTime)}</span>
        </span>,
    ];

    return (
        <div
            className={[styles.app, styles['pooltype' + currentPoolId]].join(' ')}
            style={{ backgroundImage: `url('${appBg}')` }}
        >
            <Nav />

            <div className={styles.header}>
                <div className={styles.poolInfos}>
                    <div className={styles.poolName}>
                        {poolName}
                        <div
                            className={styles.phase}
                            style={{ backgroundImage: `url('${phaseBg}')` }}
                        >
                            Phase 1
                        </div>
                    </div>
                    <div className={styles.poolTime}>
                        Deposit Time: {formatDate(poolInfo.beginVaultTime)} --{' '}
                        {formatDate(poolInfo.endVaultTime)}
                    </div>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.totalInfos}>
                    <div className={styles.label}>Total $FIRE deposited in this pool</div>
                    <div className={styles.value}>
                        $FIRE {formatNumber(poolInfo.totalStakingTokens)}
                    </div>
                </div>
            </div>
            <div className={styles.flexBox}>
                <div className={styles.left}>
                    {/* <div className={styles.poolTitle}>{poolName}</div> */}
                    <img className={styles.nftImage} src={rewardsImage} alt={nftName} />
                    <div className={styles.nftName}>{nftName}</div>
                    <div className={styles.nftDesc}>{nftDesc}</div>
                </div>

                <div className={styles.panel} style={{ backgroundImage: `url('${panelBg}')` }}>
                    <Spin spinning={!poolInfo.poolId}>
                        <div className={styles.form}>
                            <div className={styles.left}>
                                <div className={styles.blueText}>Select Deposit Token</div>
                                <div className={styles.selectBox}>
                                    <img
                                        className={styles.tokenIcon}
                                        src={FIRE_png}
                                        alt="FIRE"
                                        style={{ width: '30px' }}
                                    />
                                    <span>FIRE</span>
                                </div>
                                <div className={styles.blueText}>Deposit Rule</div>
                                <div className={styles.dashBox}>
                                    <div className={styles.blueText}>
                                        {/* Minimum {leftdeposit} $FIRE deposit required */}
                                        {formatNumber(poolInfo.takerThreshold)} FIRE ={' '}
                                        {poolInfo.rewardNftLimit} {nftName}
                                    </div>
                                    {/* {(leftdeposit > 0 && (
                                        <div className={styles.blueText}>
                                            Deposit {leftdeposit} Fire will get rewards
                                        </div>
                                    )) || (
                                        <div className={styles.blueText}>You will get rewards</div>
                                    )} */}
                                </div>
                                <Spin spinning={loadingSubmit}>
                                    <div className={styles.btn} onClick={() => handleStake()}>
                                        Deposit
                                    </div>
                                </Spin>
                                {poolInfo.rewardNftAllocNumber >= poolInfo.rewardNftMaxSupply && (
                                    <div className={styles.btnError}>
                                        This round of deposit reward has ended{' '}
                                        {poolInfo.rewardNftAllocNumber}/
                                        {poolInfo.rewardNftMaxSupply}
                                    </div>
                                )}
                            </div>
                            <div className={styles.right}>
                                <div className={styles.blueText}>
                                    Add FIRE to Deposit
                                    {/* <span className={styles.subText}>
                                        You have 1 stake remaining
                                    </span> */}
                                </div>
                                <div className={styles.inputBox}>
                                    <input
                                        type="text"
                                        placeholder={'Enter $FIRE amount'}
                                        value={inputValue}
                                        onChange={e => setInputValue(e.target.value)}
                                        disabled={loadingSubmit}
                                        readOnly={loadingSubmit}
                                    />

                                    {/* <div className={styles.remain}>
                                        Stake remaining
                                        <img src={iconXGreen} className={styles.iconX} /> 1
                                    </div> */}
                                    <div className={styles.unit}>$FIRE</div>
                                </div>

                                <div className={styles.blueText}>
                                    Available Loot Boxes
                                    <Tooltip title={tooltip1} placement="bottomLeft">
                                        <img className={styles.tipsIcon} src={tipsIcon} />
                                    </Tooltip>
                                </div>
                                <div className={styles.valueText}>{willGetNftNumber}</div>

                                <div className={styles.col2Box}>
                                    <div>
                                        <div className={styles.blueText}>
                                            My Loot Boxes
                                            <Tooltip title={tooltip2} placement="bottomLeft">
                                                <img className={styles.tipsIcon} src={tipsIcon} />
                                            </Tooltip>
                                        </div>
                                        <div className={styles.valueText}>{poolInfo.nftNumber}</div>
                                    </div>
                                    <div>
                                        <div className={styles.blueText}>$FIRE Deposit</div>
                                        <div className={styles.valueText}>
                                            {formatNumber(poolInfo.stakingNumber)}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className={styles.rewardsBox}>
                                    <div className={styles.rewardsImage}>
                                        <img src={rewardsImage} alt="" />
                                    </div>
                                    <div className={styles.rewardsInfos}>
                                        <div className={styles.label}>Staking NFT rewards</div>
                                        <div className={styles.box}>
                                            Rare box
                                            <div className={styles.unit}></div>
                                        </div>
                                        <div className={styles.label}>Deposit time</div>
                                        <div className={styles.box}>
                                            {formatDate(poolInfo.beginVaultTime)} to
                                            <br />
                                            {formatDate(poolInfo.endVaultTime)}
                                        </div>
                                        <div className={styles.label}>Pledge end time</div>
                                        <div className={styles.box}>
                                            {formatDate(poolInfo.endStakingTime)}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className={styles.tips}>
                            <div className={styles.label}>Tips:</div>
                            <ul style={{ listStyleImage: `url('${ulIcon}')` }}>
                                {tips.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </Spin>
                </div>
            </div>
            <div
                id="modal"
                className={styles.modalWrap}
                style={{ display: modalState.visibale ? 'flex' : 'none' }}
            >
                <div className={styles.modalBody} style={{ backgroundImage: `url('${modalBg}')` }}>
                    {/* <div className={styles.modalDecoration}></div> */}
                    <div className={styles.title}>
                        {modalState.title}
                        {/* 质押完成！ */}
                    </div>
                    <div className={styles.message}>
                        {modalState.message}
                        {/* 你可以在15:24:38/24/05/2022，前往质押详情页进行NFT奖励提取 */}
                    </div>
                    <div className={styles.btn} onClick={() => hideModal()}>
                        Confirm
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
