// stake
import Nav from '../../components/Nav';
import styles from './index.module.scss';
import stake1Bg from '../../assets/imgs/stake/stake1-bg.png';
import stake2Bg from '../../assets/imgs/stake/stake2-bg.png';
import stake3Bg from '../../assets/imgs/stake/stake3-bg.png';
import standardLootBox from '../../assets/imgs/stake/Standard-loot-box.png';
import epicLootBox from '../../assets/imgs/stake/Epic-loot-box.png';
import legendaryLootBox from '../../assets/imgs/stake/Legendary-loot-box.png';
import FIRE_png from '../../assets/imgs/stake/FIRE.png';
import USDT_png from '../../assets/imgs/stake/USDT.png';
import phaseBg from '../../assets/imgs/stake/phase-bg.png';
import { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
import Spin from '../../components/Spin';
import appBg from '../../assets/imgs/stake/app-bg.png';

import { Link } from 'react-router-dom';
import { message } from 'antd';
import 'antd/es/message/style/css';

// import Web3 from 'Web3';
const Web3 = window.Web3;
import IStake from '../../contract/IStake.json';
import { formatDate, formatNumber } from '../../utils';

// 合约地址
const stakeAddress = process.env.REACT_APP_STAKE;
// console.log('stakeAddress', process.env.REACT_APP_STAKE);

let timer = null;

const Index = () => {
    let [bcTime, setBcTime] = useState(0);
    const getBlockchainTime = () => {
        if (window.web3 !== undefined) {
            const _web3 = new Web3(window.web3.currentProvider);
            // 创建合约
            const contract = new _web3.eth.Contract(IStake, stakeAddress);
            contract.methods
                .getBlockchainTime()
                .call()
                .then(res => {
                    setBcTime(res - 0);
                    // console.log(new Date((res - 0) * 1000).toLocaleString());
                });
        }
    };

    const [totalClients, setTotalClients] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        if (!window.web3) {
            message.error('Please install MetaMask');
            return;
        }
        getBlockchainTime();
        return () => {
            timer && clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        // console.log('useEffect', new Date(bcTime * 1000).toLocaleString());
        timer = setTimeout(() => {
            if (bcTime) {
                setBcTime(bcTime + 1);
            }
        }, 1000);
    }, [bcTime]);

    const jumpTo = () => {};

    const [poolList, setPoolList] = useState([
        {
            type: 1,
            name: 'Standard Pool',
            limit: 0,
            sold: 0,
            discTitle: 'Limited Rewards',
            disc: 'You have change to get a premium loot Box',
            stakingLot: '20,000',
            stakingStartTime: '2022-5-9 GMT 0:00:00',
            stakingEndTime: '2022-5-10 GMT 0:00:00',
            imgNft: standardLootBox,
            imgBg: stake1Bg,
            nftName: 'Premium loot Box',
            totalStakingTokens: 0,
            stakingAddrNumber: 0,
        },
        {
            type: 2,
            name: 'Epic Pool',
            limit: 0,
            sold: 0,
            discTitle: 'Limited Rewards',
            disc: 'You have change to get a premium Epic loot Box',
            stakingLot: '40,000',
            stakingStartTime: '2022-5-10 GMT 0:00:00',
            stakingEndTime: '2022-5-11 GMT 0:00:00',
            imgNft: epicLootBox,
            imgBg: stake2Bg,
            nftName: 'Epic loot Box',
            totalStakingTokens: 0,
            stakingAddrNumber: 0,
        },
        {
            type: 3,
            name: 'Legendary Pool',
            limit: 0,
            sold: 0,
            discTitle: 'Limited Rewards',
            disc: 'You have change to get a Legendary loot Box',
            stakingLot: '90,000',
            stakingStartTime: '2022-5-11 GMT 0:00:00',
            stakingEndTime: '2022-5-12 GMT 0:00:00',
            imgNft: legendaryLootBox,
            imgBg: stake3Bg,
            nftName: 'Legendary loot Box',
            totalStakingTokens: 0,
            stakingAddrNumber: 0,
        },
    ]);

    const getTotal = tempList => {
        const stakingAddrNumber = tempList
            .map(item => item.stakingAddrNumber)
            .reduce((a, b) => a + b, 0);
        const totalStakingTokens = tempList
            .map(item => item.totalStakingTokens)
            .reduce((a, b) => a + b, 0);
        setTotalClients(stakingAddrNumber);
        setTotalAmount(totalStakingTokens);
    };
    useEffect(() => {
        // console.log('fetchData');
        // 常用方法
        const toBn = n => Web3.utils.toBN(n);
        const toWei = n => Web3.utils.toWei(toBn(n), 'ether');
        const fromWei = n => Web3.utils.fromWei(toBn(n), 'ether');
        const fetchData = async poolId => {
            if (!window.web3) {
                return;
            }
            const _web3 = new Web3(window.web3.currentProvider);
            // 创建合约
            const contract = new _web3.eth.Contract(IStake, stakeAddress);
            const resPoolInfo = await contract.methods.queryPoolInfo(poolId).call();
            // console.log('queryPoolInfo', resPoolInfo);
            const ssUserInfo = sessionStorage.getItem('userInfo');
            const userInfo = (ssUserInfo && JSON.parse(ssUserInfo)) || {};
            const resUserStake = await contract.methods
                .queryUserStake(poolId)
                .call({ from: userInfo.WalletAddress });
            // console.log('queryUserStake', resUserStake);
            const takerThreshold = fromWei(resPoolInfo['takerThreshold']);
            const stakingNumber = fromWei(resUserStake['stakingNumber']);
            const sold = resPoolInfo['rewardNftAllocNumber'] - 0;
            const totalStakingTokens = fromWei(resPoolInfo['totalStakingTokens']) - 0;
            const stakingAddrNumber = resPoolInfo['stakingAddrNumber'] - 0;
            return {
                poolId: resPoolInfo['poolId'],
                stakingStartTime: resPoolInfo['beginVaultTime'],
                stakingEndTime: resPoolInfo['endVaultTime'],
                endStakingTime: resPoolInfo['endStakingTime'],
                status: resPoolInfo['status'],
                stakingLot: takerThreshold,
                // nftNumber: resUserStake['nftNumber'],
                stakingNumber,
                limit: resPoolInfo['rewardNftMaxSupply'],
                sold,
                totalStakingTokens,
                stakingAddrNumber,
            };
        };
        const tempList = [...poolList];
        poolList.forEach(async (item, index) => {
            const poolInfo = await fetchData(item.type);
            // console.log('poolInfo', poolInfo);
            tempList.splice(index, 1, { ...item, ...poolInfo });
            setPoolList(tempList);
            getTotal(tempList);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.app} style={{ backgroundImage: `url('${appBg}')` }}>
            <Nav />

            <div className={styles.banner}>
                <div className={styles.left}>
                    <div className={styles.title}>Join the loyalty program, win NFT!</div>
                    <div className={styles.subTitle}>
                        Firework Games Loyalty Program:
                        <br />
                        Loyalty program allows our early supporters to collect NFTs to gear up for
                        our upcoming game - Spark Era .
                        <br />
                        Applicant can deposit $FIRE tokens to receive NFT as an reward.
                        <br />
                        The reward NFT will be available to claim when applicant withdraw at the end
                        of each cycle.
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.phase} style={{ backgroundImage: `url('${phaseBg}')` }}>
                        Phase 1
                    </div>
                    <div className={styles.totlaBox}>
                        <div className={styles.totlaItem}>
                            <div className={styles.label}>Total Address</div>
                            <div className={styles.value}>{totalClients}</div>
                        </div>
                        <div className={styles.divider}></div>
                        <div className={styles.totlaItem}>
                            <div className={styles.label}>Total $FIRE amount of deposit</div>
                            <div className={styles.value}>{formatNumber(totalAmount)}</div>
                        </div>
                    </div>
                </div>
            </div>

            <h3 className={styles.title}>Select pool</h3>
            <div className={styles.stakeGroup}>
                {poolList.map((item, index) => (
                    <Spin spinning={!item.poolId} key={item.type + '-span'}>
                        <div
                            key={item.type}
                            className={[styles.stakeItem, styles['pool-' + item.type]].join(' ')}
                            style={{ backgroundImage: `url('${item.imgBg}')` }}
                        >
                            <div className={styles.titleGroup}>
                                <div className={styles.title}>{item.name}</div>
                            </div>
                            <div className={styles.apy}>
                                Limited Rewards of this Pool
                                <span className={styles.percent}>
                                    {item.sold}/{item.limit}
                                </span>
                            </div>
                            <div className={styles.infos}>
                                {item.nftName}
                                {/* <div className={styles.left}>
                                    <div className={styles.discTitle}>{item.discTitle}</div>
                                    <div className={styles.disc}>{item.disc}</div>
                                </div> */}
                                {/* <div className={styles.right}>
                                    <img src={item.imgNft} alt="" />
                                </div> */}
                            </div>
                            <div className={styles.dateInfos}>
                                <div className={styles.item}>
                                    <span className={styles.label}>
                                        $FIRE minimum deposit required
                                    </span>
                                    {formatNumber(item.stakingLot)}
                                    <img
                                        className={styles.tokenIcon}
                                        src={FIRE_png}
                                        alt="FIRE"
                                        style={{ width: '30px' }}
                                    />
                                    {/* / 16,000
                                <img className={styles.tokenIcon} src={USDT_png} alt="USDT" /> */}
                                </div>
                                <div className={styles.item}>
                                    <span className={styles.label}>Deposit open time</span>
                                    {formatDate(item.stakingStartTime)}
                                </div>
                                <div className={styles.item}>
                                    <span className={styles.label}>Deposit end time</span>
                                    {formatDate(item.stakingEndTime)}
                                </div>
                            </div>
                            {/* {formatDate(item.stakingStartTime)}
                            <br />
                            {formatDate(bcTime)} bcTime
                            <br />
                            {formatDate(item.stakingEndTime)} */}
                            <div className={styles.footer}>
                                {item.stakingStartTime > bcTime && (
                                    <div className={[styles.btn, styles.disabled].join(' ')}>
                                        Deposit
                                    </div>
                                )}
                                {item.stakingEndTime < bcTime && (
                                    <Link
                                        className={styles.btn}
                                        to={`/loyalty-rewards?type=` + item.type}
                                    >
                                        View Rewards
                                    </Link>
                                )}
                                {item.stakingStartTime <= bcTime && item.stakingEndTime >= bcTime && (
                                    <Link
                                        className={styles.btn}
                                        to={`/loyalty-page?type=` + item.type}
                                    >
                                        Deposit
                                    </Link>
                                )}
                            </div>
                        </div>
                    </Spin>
                ))}
            </div>
        </div>
    );
};
export default Index;
