import React from 'react';
import styles from './v3.module.scss';
import Nav from '../../components/Nav';
import bg1 from '../../assets/imgs/game/bg1.png';
import btnDlAp from '../../assets/imgs/game/btn-dl-ap.png';
import btnDlAn from '../../assets/imgs/game/btn-dl-an.png';
import Contract from '../../components/Contract';

const Game = () => {
    return (
        <div className={styles.app}>
            <Nav />
            <div className={styles.content}>
                <div className={styles.flexBox}>
                    <div className={styles.left}></div>
                    <div className={styles.right}>
                        <h2 className={styles.title}>Spark Era:gearup for war</h2>
                        <div className={styles.subTitle}>Loyalty, LOOT, SURVIVE!</div>
                        <div className={styles.desc}>
                            Play Spark Era:Gearup for war for free.
                            <br />
                            The launch of v3 version means that the spark era metaverse will be
                            built, and gamers will be free to explore in the 3-dimensional eternal
                            galaxy based on blockchain technology and unity, surv
                        </div>
                        <div className={styles.btnGroup}>
                            <div
                                className={styles.btn}
                                style={{ backgroundImage: `url('${btnDlAp}')` }}
                            >
                                <span>Apple Store</span>
                                <span>下载</span>
                            </div>
                            <div
                                className={styles.btn}
                                style={{ backgroundImage: `url('${btnDlAn}')` }}
                            >
                                <span>Android</span>
                                <span>下载</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.footer}>
                    <Contract />
                </div>
            </div>
        </div>
    );
};

export default Game;
