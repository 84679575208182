import Nav from '../../components/Nav';
import styles from './index.module.scss';
import appBg from '../../assets/imgs/inv/app-bg.png';
import FIRE_png from '../../assets/imgs/stake/FIRE.png';
import USDT_png from '../../assets/imgs/stake/USDT.png';
import BNB_png from '../../assets/imgs/inv/BNB.png';
import QUARK_png from '../../assets/imgs/inv/QUARK.png';
import { Table } from 'antd';
import 'antd/es/table/style/css';
import { Image } from 'antd';
import 'antd/es/image/style/css';
import { message } from 'antd';
import 'antd/es/message/style/css';
import { Modal } from 'antd';
import 'antd/es/modal/style/css';
message.config({ top: 60 });
// message.config({ top: 60, duration: 0 });
// 样式覆盖
import './table.css';
import { useEffect, useState, useLayoutEffect } from 'react';
import qrImageBg from '../../assets/imgs/inv/qr-bg.jpg';
import logo1 from '../../assets/imgs/inv/logo1.png';
import logo2 from '../../assets/imgs/inv/logo2.png';
import QRCode from 'qrcode';
import axios from 'axios';
import { accountIsSameAsCurrentWallet, devLog } from '../../utils';
import { LoadingOutlined } from '@ant-design/icons';
// import Spin from '../../components/Spin';
import ICommissionAward from '../../contract/ICommissionAward.json';
import { useHistory, useLocation } from 'react-router-dom';
import { GoogleAnalyticsCurrentPageContent } from 'firework-analytics';
import { getToken } from '../../utils/auth';

// 长边缩放 content
const aspectFit = (imageWidth, imageHeight, canvasWidth, canvasHeight) => {
    const imageRate = imageWidth / imageHeight;
    const canvasRate = canvasWidth / canvasHeight;
    let [dx, dy, dw, dh] = [];
    if (imageRate >= canvasRate) {
        dw = canvasWidth;
        dh = canvasWidth / imageRate;
    } else {
        dh = canvasHeight;
        dw = canvasHeight * imageRate;
    }
    dx = (canvasWidth - dw) / 2;
    dy = (canvasHeight - dh) / 2;
    return [dx, dy, dw, dh];
};
// 短边缩放 cover
const aspectFill = (imageWidth, imageHeight, canvasWidth, canvasHeight) => {
    const imageRate = imageWidth / imageHeight;
    const canvasRate = canvasWidth / canvasHeight;
    let [sx, sy, sw, sh] = [];
    if (imageRate >= canvasRate) {
        sw = imageHeight * canvasRate;
        sh = imageHeight;
        sx = (imageWidth - sw) / 2;
        sy = 0;
    } else {
        sh = imageWidth / canvasRate;
        sw = imageWidth;
        sx = 0;
        sy = (imageHeight - sh) / 2;
    }
    return [sx, sy, sw, sh];
};
const generateQR = async text => {
    try {
        const qr = await QRCode.toCanvas(text, { margin: 1 });
        // console.log(qr);
        return qr;
    } catch (err) {
        console.error(err);
    }
};

const Index = () => {
    const [visible, setVisible] = useState(false);
    const [qrImage, setQrImage] = useState('');
    const [invCode, setInvCode] = useState('');
    const [invLink, setInvLink] = useState('');
    const [hiddenUserInfo, setHiddenUserInfo] = useState(true);

    const genQr = async () => {
        // 获取cavans元素
        const canvas = document.getElementById('myCanvas');
        // console.log('canvas.width',canvas.width);
        const cw = canvas.width;
        const ch = canvas.height;
        const ctx = canvas.getContext('2d');
        // console.log(ctx);
        // 背景图片
        const qrImageBg = document.getElementById('qrImageBg');
        // console.log('qrImageBg', qrImageBg);
        const imagw = qrImageBg.width;
        const imageh = qrImageBg.height;
        ctx.drawImage(qrImageBg, ...aspectFill(imagw, imageh, cw, ch), 0, 0, cw, ch);
        // 文字
        ctx.fillStyle = 'white';
        ctx.font = 'bold 80px ShentoxBold';
        ctx.fillText('FIGHT', 60, 200);
        ctx.fillText('ALONGSIDE!', 60, 270);
        ctx.font = '30px ShentoxMedium';
        ctx.fillText("Let's build our own Empire in the chaos", 60, 350);
        ctx.fillText('universe!', 60, 380);
        ctx.font = '120px ShentoxMedium';
        ctx.fillText('5%', 55, 520);
        ctx.font = '40px ShentoxMedium';
        ctx.fillText('Invite your comrade!', 230, 465);
        ctx.font = '31px ShentoxMedium';
        ctx.fillText('Welcome rewards at most!', 230, 510);
        // 底部
        ctx.font = '20px ShentoxLight';
        ctx.fillText('Please scan QR code for more reward details.', 200, 1050);
        // 底部logo
        const logo1 = document.getElementById('logo1');
        const logo2 = document.getElementById('logo2');
        ctx.drawImage(logo1, 585, 989);
        ctx.drawImage(logo2, 195, 960);
        // 二维码
        const qrImg = await generateQR(invLink);
        ctx.drawImage(qrImg, 30, 890, 160, 160);
        // 转换base64
        const base64 = canvas.toDataURL('image/png');
        // 显示图像
        setQrImage(base64);
        setVisible(true);
    };

    const columns = [
        { title: 'Rank', dataIndex: 'rank', width: 100, align: 'center' },
        { title: 'ID', dataIndex: 'id', width: 180, align: 'center' },
        { title: 'Number of invitees', dataIndex: 'noInv', width: 180, align: 'center' },
        {
            title: 'Total bonus',
            dataIndex: 'bonus',
            align: 'center',
            render: (obj, row, index) => (
                <div className="bonus-group">
                    <img className="token-icon" src={FIRE_png} />
                    {obj.fire}
                    <img className="token-icon" src={QUARK_png} />
                    {obj.quark}
                    <img className="token-icon" src={USDT_png} />
                    {obj.usdt || 0}
                    <img className="token-icon" src={BNB_png} />
                    {obj.bnb}
                </div>
            ),
        },
    ];

    // 获取获取邀请码
    const history = useHistory();
    // console.log('history', history);
    const location = useLocation();
    // console.log(location.pathname);
    useEffect(() => {
        const getInvitationCode = async () => {
            try {
                // const account = localStorage.getItem('account');
                // if (!account) return;

                // const userEmail = await axios
                //     .get(process.env.REACT_APP_USER_NODEJS + `/api/user/` + account)
                //     .then(res => {
                //         if (res.data && res.data.data && res.data.data.UserName) {
                //             setHiddenUserInfo(false);
                //             return res.data.data.UserName;
                //         } else {
                //             setHiddenUserInfo(true);
                //             return '';
                //             // history.push({ pathname: '/register' });
                //             // throw new Error('getUserInfo error');
                //         }
                //     });
                // devLog('getUserInfo', userRes);
                // if (userRes.data.code !== 200) throw new Error('getUserInfo error');
                // const { UserName: userEmail } = userRes.data.data;
                // if (!userEmail) throw new Error("user doesn't have an email");

                // 获取邀请码
                const codeRes = await axios.get(
                    `${process.env.REACT_APP_AXIOS_URL}/api/v1/informationCenter/getInviteCode`,
                    { headers: { authToken: getToken() } },
                    // { params: { emailAddr: userEmail } },
                );
                // devLog('getInviteCode', codeRes);
                const { data: code } = codeRes.data;
                if (!code) throw new Error("code doesn't exist");
                const { href } = window.location;
                const pagePath = location.pathname;
                const host = href.split(pagePath)[0];
                // const link = `${host}/register?code=${code}`;
                const link = `${process.env.REACT_APP_SSO_URL}/login?act=reg&code=${code}`;
                setInvCode(code);
                setInvLink(link);
                setHiddenUserInfo(false);
            } catch (error) {
                console.error(error);
            }
        };
        getInvitationCode();
        // eslint-disable-next-line
    }, []);

    // 复制内容到剪切板
    const copyToClipboard = async () => {
        try {
            const clipboardObj = navigator.clipboard;
            if (!clipboardObj) throw new Error('Clipboard not supported');
            await clipboardObj.writeText(invLink);
            message.success('Copied to clipboard');
        } catch (error) {
            devLog(error);
        }
    };

    // 获取已获得的奖励资产
    const [rebateInfo, setRebateInfo] = useState({
        fire: 0,
        fireTotal: 0,
        fireAdd: '0x',
        fireLoading: false,
        quark: 0,
        quarkTotal: 0,
        quarkAdd: '0x',
        quarkLoading: false,
        usdt: 0,
        usdtTotal: 0,
        usdtAdd: '0x',
        usdtLoading: false,
        bnb: 0,
        bnbTotal: 0,
        bnbAdd: '0x',
        bnbLoading: false,
        noInv: 0,
    });
    const [rebateRank, setRebateRank] = useState([
        // { key: 1, rank: 1, id: 'Aokoko', noInv: 123, bonus: { fire: 123, quark: 456, usdt: 789 } },
        // { key: 2, rank: 2, id: 'Aokoko', noInv: 456, bonus: { fire: 123, quark: 456, usdt: 789 } },
        // { key: 3, rank: 3, id: 'Aokoko', noInv: 789, bonus: { fire: 123, quark: 456, usdt: 789 } },
    ]);
    const addrBnb = process.env.REACT_APP_ERC20_BNB,
        addrUsdt = process.env.REACT_APP_ERC20_USDT,
        addrQuark = process.env.REACT_APP_ERC20_QUARK,
        addrFire = process.env.REACT_APP_ERC20_FIRE;
    const getRebateInfo = async () => {
        try {
            // const account = localStorage.getItem('account');
            // if (!account) return;
            // const userRes = await axios.get(
            //     process.env.REACT_APP_USER_NODEJS + `/api/user/` + account,
            // );
            // devLog('getUserInfo', userRes);
            // if (userRes.data.code !== 200) throw new Error('getUserInfo error');
            // const { UserName: userEmail } = userRes.data.data;
            // if (!userEmail) throw new Error("user doesn't have an email");

            // 获取当前用户的返佣奖励信息
            const rebateRes = await axios.get(
                `${process.env.REACT_APP_AXIOS_URL}/api/v1/informationCenter/showRebateInfo`,
                { headers: { authToken: getToken() } },
                // { params: { emailAddr: userEmail } },
                // { params: { emailAddr: 'seedify2' } },
            );
            // devLog('showRebateInfo', rebateRes);
            const { erc, invite_cnt, invite_rank } = rebateRes.data.data;
            // devLog('erc, invite_cnt, invite_rank', erc, invite_cnt, invite_rank);
            // 赋值当前用户的奖励资产
            erc.forEach(item => {
                if (item.erc20_addr === addrFire.toLowerCase()) {
                    Object.assign(rebateInfo, {
                        fire: item.amount,
                        // fire: 0.2,
                        fireTotal: item.use_amount,
                        fireAdd: addrFire,
                    });
                }
                if (item.erc20_addr === addrQuark.toLowerCase()) {
                    Object.assign(rebateInfo, {
                        quark: item.amount,
                        quarkTotal: item.use_amount,
                        quarkAdd: addrQuark,
                    });
                }
                if (item.erc20_addr === addrUsdt.toLowerCase()) {
                    Object.assign(rebateInfo, {
                        usdt: item.amount,
                        usdtTotal: item.use_amount,
                        usdtAdd: addrBnb,
                    });
                }
                if (item.erc20_addr === addrBnb.toLowerCase()) {
                    Object.assign(rebateInfo, {
                        bnb: item.amount,
                        bnbTotal: item.use_amount,
                        bnbAdd: addrBnb,
                    });
                }
            });
            // 邀请人数
            setRebateInfo({ ...rebateInfo, noInv: invite_cnt });
            // 排行
            setRebateRank(
                invite_rank.map((item, index) => ({
                    // { key: 1, rank: 1, id: 'Aokoko', noInv: 123, bonus: { fire: 123, quark: 456, usdt: 789 } },
                    // email_addr: "seedify4"
                    // invitees: 0
                    // total_bonous: 0.02
                    key: index + 1,
                    rank: index + 1,
                    id: item.userid,
                    // id: item.email_addr,
                    // id: item.email_addr.slice(0, 5) + '*',
                    noInv: item.invitees,
                    bonus: { fire: item.Fire, quark: item.Quark, usdt: item.Usdt, bnb: item.Bnb },
                })),
            );
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        const token = getToken();
        if (!token) {
            // 请先登录
            Modal.confirm({
                title: 'Tips',
                content: 'Please login first',
                okText: 'Login',
                cancelText: 'Cancel',
                getContainer: false,
            });
            return;
        }
        const ssUserInfo = sessionStorage.getItem('userInfo');
        const account = token && ssUserInfo && JSON.parse(ssUserInfo).WalletAddress;
        if (!account) {
            // 请绑定钱包
            Modal.confirm({
                title: 'Tips',
                content: 'Please bind a wallet first',
                okText: 'Go bind',
                cancelText: 'Cancel',
                getContainer: false,
            });
        }
        getRebateInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 提取佣金奖励
    const getRebate = async tokenName => {
        // todo 移植allowRequestWeb3方法
        const token = getToken();
        // 请先登录
        if (!token) return;
        const ssUserInfo = sessionStorage.getItem('userInfo');
        const account = token && ssUserInfo && JSON.parse(ssUserInfo).WalletAddress;
        if (!account) {
            // 请绑定钱包
            Modal.confirm({
                title: 'Tips',
                content: 'Please bind a wallet first',
                okText: 'Go bind',
                cancelText: 'Cancel',
                getContainer: false,
            });
            return;
        }

        // 判断是否为当前钱包链接地址
        const isSame = await accountIsSameAsCurrentWallet();
        if (!isSame) {
            message.error(
                'The wallet address bound by the current user is different from the connected wallet address. The request is aborted.',
            );
            return;
        }
        // 加载中
        const loadindKey = `${tokenName}Loading`;
        const { [loadindKey]: loading } = rebateInfo;
        if (loading) return;
        try {
            setRebateInfo({ ...rebateInfo, [loadindKey]: true });
            // const account = localStorage.getItem('account');
            // const userRes = await axios.get(
            //     process.env.REACT_APP_USER_NODEJS + `/api/user/` + account,
            // );
            // devLog('getUserInfo', userRes);
            // if (userRes.data.code !== 200) throw new Error('getUserInfo error');
            // const { UserName: userEmail } = userRes.data.data;
            // if (!userEmail) throw new Error("user doesn't have an email");
            let erc20Addr = addrBnb,
                emailAddr = account;
            // emailAddr = 'seedify2';
            tokenName === 'fire' && (erc20Addr = addrFire);
            tokenName === 'quark' && (erc20Addr = addrQuark);
            tokenName === 'usdt' && (erc20Addr = addrUsdt);
            // 提交请求
            const res = await axios.get(
                process.env.REACT_APP_AXIOS_URL + `/api/v1/informationCenter/getRebate`,
                {
                    params: { emailAddr, erc20Addr: erc20Addr.toLowerCase() },
                    headers: { authToken: token },
                },
            );
            devLog('getRebate', res);
            if (res.data.code !== 200) throw new Error(res.data.msg || 'GetRebate error');
            let { v, r, s, claim_content: claimContent } = res.data.data;
            v = '0x' + v;
            r = '0x' + r;
            s = '0x' + s;
            claimContent = '0x' + claimContent;
            // 创建合约实例
            // 合约地址
            const AwardAddress = process.env.REACT_APP_AWARD;
            if (window.web3 !== undefined) {
                const _web3 = new window.Web3(window.web3.currentProvider);
                // 创建合约
                devLog('AwardAddress', AwardAddress);
                const contract = new _web3.eth.Contract(ICommissionAward, AwardAddress);
                // const claimContent = erc;
                devLog('创建合约', account, claimContent, v, r, s);
                const awardClaimRes = await contract.methods
                    .awardClaim(account, claimContent, v, r, s)
                    .send({ from: account });
                console.log('awardClaim', awardClaimRes);
                if (awardClaimRes.status) {
                    message.success('Claim succeeded');
                }
            }
            GoogleAnalyticsCurrentPageContent('CLAM');
            // 刷新数据
            getRebateInfo();
        } catch (error) {
            error.message && message.error(error.message);
            console.error(error);
        } finally {
            setRebateInfo({ ...rebateInfo, [loadindKey]: false });
        }
    };

    return (
        <>
            <div className={styles.app} style={{ backgroundImage: `url('${appBg}')` }}>
                <Nav />

                <div className={styles.content}>
                    {!hiddenUserInfo && (
                        <div className={styles.flexBox}>
                            <div className={styles.flexLeft}>
                                <h2>Trading rebate</h2>
                                <div className={styles.linkBox}>
                                    {/* <div className={styles.label}>Invitation link</div>
                        <div className={styles.link}>{invLink}</div> */}
                                    <div className={styles.label}>Invitation code</div>
                                    <div className={styles.link}>{invCode}</div>
                                </div>
                                {invCode && (
                                    <div className={styles.btnGroup}>
                                        <div
                                            className={styles.btn}
                                            onClick={() => copyToClipboard()}
                                        >
                                            Copy link
                                        </div>
                                        <div className={styles.btn} onClick={() => genQr()}>
                                            Invitation poster
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={styles.flexRight}>
                                <h3>Withdrawable rewards</h3>

                                <div className={[styles.flexBox, styles.clam].join(' ')}>
                                    <div className={styles.flexLeft}>
                                        <div className={styles.clamBox}>
                                            <img className={styles.tokenIcon} src={FIRE_png} />
                                            <span className={styles.number}>{rebateInfo.fire}</span>

                                            {rebateInfo.fire ? (
                                                <div
                                                    className={styles.btn}
                                                    onClick={() => {
                                                        getRebate('fire');
                                                    }}
                                                >
                                                    {rebateInfo.fireLoading && <LoadingOutlined />}
                                                    CLAM
                                                </div>
                                            ) : (
                                                <div
                                                    className={[styles.btn, styles.disabled].join(
                                                        ' ',
                                                    )}
                                                >
                                                    CLAM
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.clamBox}>
                                            <img className={styles.tokenIcon} src={QUARK_png} />
                                            <span className={styles.number}>
                                                {rebateInfo.quark}
                                            </span>
                                            {rebateInfo.quark ? (
                                                <div
                                                    className={styles.btn}
                                                    onClick={() => {
                                                        getRebate('quark');
                                                    }}
                                                >
                                                    {rebateInfo.quarkLoading && <LoadingOutlined />}
                                                    CLAM
                                                </div>
                                            ) : (
                                                <div
                                                    className={[styles.btn, styles.disabled].join(
                                                        ' ',
                                                    )}
                                                >
                                                    CLAM
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.clamBox}>
                                            <img className={styles.tokenIcon} src={USDT_png} />
                                            <span className={styles.number}>{rebateInfo.usdt}</span>
                                            {rebateInfo.usdt ? (
                                                <div
                                                    className={styles.btn}
                                                    onClick={() => {
                                                        getRebate('usdt');
                                                    }}
                                                >
                                                    {rebateInfo.usdtLoading && <LoadingOutlined />}
                                                    CLAM
                                                </div>
                                            ) : (
                                                <div
                                                    className={[styles.btn, styles.disabled].join(
                                                        ' ',
                                                    )}
                                                >
                                                    CLAM
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.clamBox}>
                                            <img className={styles.tokenIcon} src={BNB_png} />
                                            <span className={styles.number}>{rebateInfo.bnb}</span>
                                            {rebateInfo.bnb ? (
                                                <div
                                                    className={styles.btn}
                                                    onClick={() => {
                                                        getRebate('bnb');
                                                    }}
                                                >
                                                    {rebateInfo.bnbLoading && <LoadingOutlined />}
                                                    CLAM
                                                </div>
                                            ) : (
                                                <div
                                                    className={[styles.btn, styles.disabled].join(
                                                        ' ',
                                                    )}
                                                >
                                                    CLAM
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.flexMiddle}>
                                        <div className={styles.borderBox}>
                                            <div className={styles.borderBoxTitle}>
                                                Number invited
                                            </div>
                                            <div className={styles.borderBoxContetn}>
                                                <div className={styles.number}>
                                                    {rebateInfo.noInv}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.flexRight}>
                                        <div className={styles.borderBox}>
                                            <div className={styles.borderBoxTitle}>
                                                Received rewards
                                            </div>
                                            <div className={styles.borderBoxContetn}>
                                                <div className={styles.totalBox}>
                                                    <img
                                                        className={styles.tokenIcon}
                                                        src={FIRE_png}
                                                    />
                                                    <div className={styles.infos}>
                                                        <div className={styles.label}>FIRE</div>
                                                        <div className={styles.value}>
                                                            {rebateInfo.fireTotal}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.totalBox}>
                                                    <img
                                                        className={styles.tokenIcon}
                                                        src={QUARK_png}
                                                    />
                                                    <div className={styles.infos}>
                                                        <div className={styles.label}>QUARK</div>
                                                        <div className={styles.value}>
                                                            {rebateInfo.quarkTotal}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.totalBox}>
                                                    <img
                                                        className={styles.tokenIcon}
                                                        src={USDT_png}
                                                    />
                                                    <div className={styles.infos}>
                                                        <div className={styles.label}>USDT</div>
                                                        <div className={styles.value}>
                                                            {rebateInfo.usdtTotal}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.totalBox}>
                                                    <img
                                                        className={styles.tokenIcon}
                                                        src={BNB_png}
                                                    />
                                                    <div className={styles.infos}>
                                                        <div className={styles.label}>BNB</div>
                                                        <div className={styles.value}>
                                                            {rebateInfo.bnbTotal}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={styles.flexBox}>
                        <div className={styles.flexLeft}>
                            <h3>Invitation rules</h3>
                            <div className={styles.descriptionBox}>
                                <p>1.Play Spark Era:Gearup for war for free.</p>
                                <p>
                                    2.The launch of v3 version means that the spark era metaverse
                                    will be built, and gamers will be free to explore in the
                                    3-dimensional eternal galaxy based on blockchain technology and
                                    unity, surviving and fighting under the dark forest law.
                                </p>
                                <p>
                                    3.Winning glory for their camp guild and getting generous
                                    economic benefits.
                                </p>
                                <p>
                                    4.Winning glory for their camp guild and getting generous
                                    economic benefits.
                                </p>
                            </div>
                        </div>
                        <div className={styles.flexRight}>
                            <h3>Invitation rank</h3>
                            <Table
                                dataSource={rebateRank}
                                columns={columns}
                                scroll={{ y: 360 }}
                                pagination={false}
                                size="small"
                            />
                        </div>
                    </div>
                </div>
                <Image
                    style={{ display: 'none' }}
                    preview={{
                        visible,
                        src: qrImage,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <canvas id="myCanvas" width="673" height="1076" style={{ display: 'none' }}>
                    您的浏览器不支持 Canvas
                </canvas>

                <img
                    style={{ display: 'none' }}
                    id="qrImageBg"
                    src={qrImageBg}
                    width="1622"
                    height="2593"
                />
                <img style={{ display: 'none' }} id="logo1" src={logo1} width="72" height="73" />
                <img style={{ display: 'none' }} id="logo2" src={logo2} width="330" height="74" />
            </div>
        </>
    );
};

export default Index;
