import React from 'react';
import Routes from './routes/routes';

// 处理切换网络事件
window.ethereum &&
    window.ethereum.on('chainChanged', chainId => {
        location.reload();
        console.log('chainChanged', chainId);
        if (chainId !== process.env.REACT_APP_CHAIN_ID) {
            console.warn('Please change to the BSC main network.');
        }
        // localStorage.removeItem('account');
        // localStorage.removeItem('accountEmail');
        // if (window.location.href.indexOf('register') === -1) {
        //     location.reload();
        //     localStorage.removeItem('accountEmail');
        // }
    });
// 处理切换账户事件
window.ethereum &&
    window.ethereum.on('accountsChanged', () => {
        localStorage.removeItem('Address');
        location.reload();
        // localStorage.removeItem('accountEmail');

        // if (window.location.href.indexOf('register') === -1) {
        //     location.reload();
        //     localStorage.removeItem('accountEmail');
        // }
    });

async function getWallet() {
    if (window.ethereum) {
        const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
        localStorage.setItem('Address', account);
    }
}

const App = () => {
    return <Routes></Routes>;
};

export default App;
