import React from 'react';
import styles from './index.module.scss';
import Button from '../../components/Button';
import Tabs from '../../components/Tabs';

class Guide extends React.Component {
    render() {
        return (
            <section className={styles.guide}>
                <header>
                    <h1>GALAXY GUIDE</h1>
                    <div>
                        <div className={styles.headerLeft}>
                            <p>WELCOME, TRAVELER.</p>
                            <p>HERE YOU CAN LEARN ABOUT THE ENTIRE Spark Era UNIVERSE.</p>
                        </div>
                        <div className={styles.headerRight}>
                            <div className={styles.btnGroup}>
                                <Button>GITBOOK</Button>
                                <Button>YOUTUBE</Button>
                            </div>
                            <p>*CLICK HERE FOR MORE GAME INFORMATION</p>
                        </div>
                    </div>
                </header>
                <article>
                    <Tabs />
                </article>
            </section>
        );
    }
}

export default Guide;
