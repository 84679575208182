import React from 'react';
import styles from './index.module.scss';
import Nav from '../../components/Nav';
import bg1 from '../../assets/imgs/game/bg1.png';
import bg2 from '../../assets/imgs/game/bg2.png';
import bg3 from '../../assets/imgs/game/bg3.png';
import logo1 from '../../assets/imgs/game/icon1.png';
import logo2 from '../../assets/imgs/game/icon2.png';
import logo3 from '../../assets/imgs/game/icon3.png';
import { useHistory } from "react-router-dom";

const Game = () => {
    const gameList = [
        {
            id: 1,
            title: 'Gearup for War',
            bg: bg1,
            url: '/game/v3',
            logo: logo1,
            desc: `Spark Era mobile game is officially launched! Surviving and
            fighting under the dark forest law, winning glory for your camp
            guild and getting generous economic benefits.`,
        },
        {
            id: 2,
            // title: 'INTERSTELLAR EXPLORATION',
            title: 'Interstellar Exploration',
            bg: bg2,
            url: 'http://www.bing.com',
            logo: logo2,
            desc: `Form your fleet to go to the interstellar mining pool to collect resources and gain
            income! Fight against cosmic random events that might arise!`,
        },
        {
            id: 3,
            // title: 'INTERSTELLAR CONQUEST',
            title: 'Interstellar Conquest',
            bg: bg3,
            url: '/game/v2',
            logo: logo3,
            desc: `Pick your warriors, go to no man’s land to perform interstellar missions, and enjoy the
            fun of playing and earning with games.`,
        },
    ];

    let history = useHistory();
    const jump = url => {
        if (url.startsWith('http')) {
            location.href = url;
        } else {
            history.push({
                pathname: url,
            });
        }
    };

    return (
        <div className={styles.app}>
            <Nav />
            <div className={styles.content}>
                <div className={styles.flexBox}>
                    {gameList.map(item => (
                        <div
                            className={styles.flexItem}
                            key={item.id}
                            onClick={() => {
                                jump(item.url);
                            }}
                        >
                            <div
                                className={styles.flexInner}
                                style={{ backgroundImage: `url('${item.bg}')` }}
                            >
                                <div className={styles.wrapBox}>
                                    <div className={styles.describe}>{item.desc}</div>
                                    <div className={styles.titleBox}>
                                        <img src={item.logo} className={styles.logo} alt="" />
                                        <div className={styles.dividing}></div>
                                        <div className={styles.title}>{item.title}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Game;
