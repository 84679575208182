import React from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { routesConfig } from './routesConfig';
import axios from 'axios';
import { Modal } from 'antd';
import { getToken } from '../utils/auth';

function requireAuth(props, route) {
    // console.log('requireAuth', props);

    // const ssUserInfo = sessionStorage.getItem('userInfo');
    // const hasAccount = ssUserInfo && JSON.parse(ssUserInfo).UserName;
    // const token = getToken()

    // if (props.location.pathname === '/invite' && !token) {
    //     toast.warn('Please login first');
    //     // return <Redirect to={`/register?redirect=${window.location.href}`} />;
    // }
    return <route.component {...props} />;
}

const Routes = () => {
    return (
        <Router>
            <Switch>
                {routesConfig.map(route => (
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        render={props => requireAuth(props, route)}
                    ></Route>
                ))}
                <Redirect to="/" />
            </Switch>
            <ToastContainer />
        </Router>
    );
};

export default Routes;
