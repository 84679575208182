import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import styles from './index.module.scss';
import aritcleList from './aritcle';
import Contract from '../../components/Contract';
import { isMobile } from '../../utils';

const LatestNews = props => {
    const [showPrePage, setShowPrePage] = useState(false);
    const [showNextPage, setShowNextPage] = useState(true);
    const [aritcleId, setAritcleId] = useState(Number(props.match.params.id) || 0);

    useEffect(() => {
        setAritcleId(Number(props.match.params.id));
        if (aritcleId === 0) {
            setShowPrePage(false);
        } else {
            console.log(1231312);
            setShowPrePage(true);
        }

        if (aritcleId === aritcleList.length - 1) {
            setShowNextPage(false);
        } else {
            setShowNextPage(true);
        }
        console.log(aritcleId, aritcleList.length);
    }, [props.match.params.id, aritcleId]);

    return (
        <div className={styles.LatestNews}>
            <Nav />

            <div className={styles.content} style={{ width: isMobile() ? '90vw' : '' }}>
                <div
                    className={styles.Title}
                    dangerouslySetInnerHTML={{ __html: aritcleList[aritcleId].title }}
                ></div>
                <div dangerouslySetInnerHTML={{ __html: aritcleList[aritcleId].time }}></div>
                <div dangerouslySetInnerHTML={{ __html: aritcleList[aritcleId].detail }}></div>

                <div className={styles.pagination}>
                    {showPrePage && (
                        <Link className={styles.prePage} to={`/LatestNews/${aritcleId - 1}`}>
                            Previous news
                        </Link>
                    )}
                    {showNextPage && (
                        <Link className={styles.nextPage} to={`/LatestNews/${aritcleId + 1}`}>
                            Next news
                        </Link>
                    )}
                </div>

                <Contract />
            </div>
        </div>
    );
};

export default LatestNews;
