import Nav from '../../components/Nav';
import styles from './rewards.module.scss';
import appBg1 from '../../assets/imgs/stake/page-bg1.png';
import appBg2 from '../../assets/imgs/stake/page-bg2.png';
import appBg3 from '../../assets/imgs/stake/page-bg3.png';
import panelBg1 from '../../assets/imgs/stake/page-panel-bg1.png';
import panelBg2 from '../../assets/imgs/stake/page-panel-bg2.png';
import panelBg3 from '../../assets/imgs/stake/page-panel-bg3.png';
import nftImage1 from '../../assets/imgs/stake/Standard-loot-box.png';
import FIRE_png from '../../assets/imgs/stake/FIRE.png';
import iconXGreen from '../../assets/imgs/stake/icon-x-green.png';
import standardLootBox from '../../assets/imgs/stake/Standard-loot-box.png';
import epicLootBox from '../../assets/imgs/stake/Epic-loot-box.png';
import legendaryLootBox from '../../assets/imgs/stake/Legendary-loot-box.png';
import ulIcon from '../../assets/imgs/stake/ul-icon.png';
import { useState, useRef } from 'react';
import { useEffect } from 'react';
// import Web3 from 'Web3';
const Web3 = window.Web3;
import IStake from '../../contract/IStake.json';
import { initWallet } from './service';
import fire from '../../contract/fire.json';
const queryString = require('query-string');
import Spin from '../../components/Spin';
import { accountIsSameAsCurrentWallet, devLog, formatDate, formatNumber } from '../../utils';
import phaseBg from '../../assets/imgs/stake/phase-bg.png';
import tipsIcon from '../../assets/imgs/stake/tips-icon.png';
import { Tooltip } from 'antd';
import 'antd/es/tooltip/style/css';
import modalBg from '../../assets/imgs/stake/modal-bg.png';
import { message } from 'antd';
import 'antd/es/message/style/css';
import { useHistory } from 'react-router-dom';

// 合约地址
const stakeAddress = process.env.REACT_APP_STAKE;
const ERC20fireAddress = process.env.REACT_APP_ERC20_FIRE;
const ERC20quarkAddress = process.env.REACT_APP_ERC20_QUARK;

let timer = null;
// 初始化合约
let contractIStake, _web3;

const Index = () => {
    const history = useHistory();

    let [bcTime, setBcTime] = useState(0);

    const ssUserInfo = sessionStorage.getItem('userInfo');
    const userInfo = (ssUserInfo && JSON.parse(ssUserInfo)) || {};
    // 常用方法
    const toBn = n => _web3.utils.toBN(n);
    const toWei = n => _web3.utils.toWei(toBn(n), 'ether');
    const fromWei = n => _web3.utils.fromWei(toBn(n), 'ether');
    // const formatDate = date => (date ? new Date(date * 1000).toLocaleString() : '-');

    const urlPramas = queryString.parse(location.search);
    const currentPoolId = urlPramas.type;
    const [modalState, setModalState] = useState({
        visibale: false,
        title: 'title',
        message: 'message',
    });

    // onConfirm: () => {},
    let onClose = useRef(null);
    const showModal = (title = modalState.title, message = modalState.message, callback) => {
        setModalState({
            visibale: true,
            title,
            message,
        });
        onClose.current = callback;
        const elem = document.getElementById('modal');
        elem.querySelector('div').style.display = 'flex';
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        setTimeout(() => {
            elem.style['backdrop-filter'] = 'blur(10px)';
        }, 0);
    };
    const hideModal = () => {
        const elem = document.getElementById('modal');
        elem.style['backdrop-filter'] = '';
        elem.querySelector('div').style.display = 'none';
        document.getElementsByTagName('body')[0].style.overflow = '';
        setTimeout(() => {
            setModalState({
                ...modalState,
                visibale: false,
            });
            // console.log(typeof onClose.current);
            if (typeof onClose.current === 'function') {
                onClose.current();
            }
        }, 300);
    };

    // const onClose = () => {};

    // const get
    // useEffect(() => {}, []);

    // const handleStake = async () => {
    //     const amount = inputValue - 0;

    //     const currentAddress = localStorage.getItem('account');
    //     /**
    //      *  check list
    //      * 1. 判断质押池是否在可存入期间
    //      * 2. 判断用户账户余额
    //      * 3. 查询授权金额
    //      * 4. 存入质押
    //      *
    //      */

    //     // 输入金额是否合法
    //     if (!/^[1-9]\d*$/.test(amount)) {
    //         showModal('无效金额', '请输入有效的金额');
    //         return;
    //     }

    //     // 获取区块链时间
    //     const bcTime = await contractIStake.methods.getBlockchainTime().call();

    //     //  1. 判断质押池是否在可deposit期间
    //     const poolInfos = await contractIStake.methods.queryPoolInfo(currentPoolId).call();
    //     console.log('poolInfos', poolInfos);

    //     if (poolInfos['beginVaultTime'] > bcTime || bcTime > poolInfos['endVaultTime']) {
    //         // 隐式转换，问题不大
    //         console.log('当前服务器时间', formatDate(bcTime));
    //         console.log('开始存入时间', formatDate(poolInfos['beginVaultTime']));
    //         console.log('结束存入时间', formatDate(poolInfos['endVaultTime']));
    //         showModal('无法存入', '该矿池不在可存入期间，请选择其他矿池');
    //         return;
    //     }

    //     // 2. 判断用户账户余额
    //     var tfreNum = new _web3.eth.Contract(fire, ERC20fireAddress);
    //     const balance = await tfreNum.methods.balanceOf(currentAddress).call();
    //     if (fromWei(balance) < amount) {
    //         console.log(`账户Fire余额：${fromWei(balance)}`);
    //         showModal('余额不足', '你的账户中代币不足');
    //         return;
    //     }

    //     //  3. 查询erc20 授权金额
    //     var tqukNum = new _web3.eth.Contract(fire, ERC20fireAddress);
    //     const amountFire = await tqukNum.methods.allowance(currentAddress, stakeAddress).call();
    //     console.log(`已授权fire：${fromWei(amountFire)}，订单金额：${amount}`);
    //     // 如果当前授权金额小于存入金额
    //     if (toBn(amountFire).lt(toWei(amount))) {
    //         // 重新授权
    //         console.log('授权Fire');
    //         await tfreNum.methods
    //             .approve(stakeAddress, toWei(amount))
    //             .send({ from: currentAddress });
    //     }

    //     const res = await contractIStake.methods.deposit(currentPoolId, amount).send({
    //         from: currentAddress,
    //     });
    //     console.log('deposit', res);
    // };

    // 获取区块链时间
    useEffect(() => {
        if (window.web3 !== undefined) {
            _web3 = new Web3(window.web3.currentProvider);
            // 创建质押合约
            const contract = new _web3.eth.Contract(IStake, stakeAddress);
            contractIStake = contract;

            // test
            // contractIStake.methods
            //     .getTotalPoolSupply()
            //     .call()
            //     .then(res => {
            //         console.log(`目前共有矿池1-` + res);
            //     });
        }
        const fnSync = async () => {
            if (window.web3 !== undefined) {
                const _web3 = new Web3(window.web3.currentProvider);
                // 创建质押合约
                const contract = new _web3.eth.Contract(IStake, stakeAddress);
                const bcTime = await contract.methods.getBlockchainTime().call();
                setBcTime(bcTime - 0);
            }
        };

        // initWallet();
        fnSync();
        return () => {
            timer && clearTimeout(timer);
        };
    }, []);
    // 区块链时间自增
    useEffect(() => {
        // console.log('useEffect', new Date(bcTime * 1000).toLocaleString());
        timer = setTimeout(() => {
            if (bcTime) {
                setBcTime(bcTime + 1);
            }
        }, 1000);
    }, [bcTime]);

    // const [inputValue, setInputValue] = useState('');
    // 质押池信息
    const [poolInfo, setPoolInfo] = useState({
        poolId: '',
        beginVaultTime: '',
        endVaultTime: '',
        endStakingTime: '',
        // rewardNftType
        // 0 初始化，1 存入等待，2 代币存入中，3 质押中，4 质押结束提币中，5 质押池关闭
        status: '',
        takerThreshold: 0,
        // 待领取的NFT数量
        nftNumber: 0,
        // 已质押的代币数量
        stakingNumber: 0,
    });

    // 1 禁止提取 2 可以提取 3 已经提取
    const [btnState, setBtnState] = useState(1);

    // const [leftdeposit, setLeftdeposit] = useState(0);
    const fetchData = async () => {
        // const data = await fetch('https://yourapi.com');
        const account = userInfo.WalletAddress;
        const accountEmail = userInfo.UserName;
        // todo 逻辑可以优化
        if (!account) {
            devLog('account is null');
            if (accountEmail) {
                showModal('Tips', 'You need to bind the wallet account, please bind first', () => {
                    history.push('/register');
                });
            } else {
                showModal('Tips', 'Please login or register an account', () => {
                    history.push('/register');
                });
            }
            return;
        }

        const resPoolInfo = await contractIStake.methods.queryPoolInfo(currentPoolId).call();
        devLog('queryPoolInfo', resPoolInfo);
        const resUserStake = await contractIStake.methods
            .queryUserStake(currentPoolId)
            .call({ from: account });
        devLog('queryUserStake', resUserStake);
        const takerThreshold = fromWei(resPoolInfo['takerThreshold']) - 0;
        const stakingNumber = fromWei(resUserStake['stakingNumber']) - 0;
        const nftNumber = resUserStake['nftNumber'] - 0;
        setPoolInfo({
            ...poolInfo,
            poolId: resPoolInfo['poolId'],
            beginVaultTime: resPoolInfo['beginVaultTime'],
            endVaultTime: resPoolInfo['endVaultTime'],
            endStakingTime: resPoolInfo['endStakingTime'],
            status: resPoolInfo['status'],
            takerThreshold,
            nftNumber,
            stakingNumber,
            totalStakingTokens: fromWei(resPoolInfo['totalStakingTokens']),
        });
        // console.log('endStakingTime', resPoolInfo['endStakingTime']);
        // console.log('nftNumber', nftNumber);
        // if()
        // setLeftdeposit(takerThreshold - stakingNumber);
    };
    // 获取质押池信息
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 计算剩余时间
    const [countdown, setCountdown] = useState(['']);
    useEffect(() => {
        // console.log('计算剩余时间', poolInfo.endStakingTime - bcTime);
        if (bcTime && poolInfo.endStakingTime) {
            const timeLeft = poolInfo.endStakingTime - bcTime;
            if (timeLeft > 0) {
                const sec = Math.floor(timeLeft % 60);
                const min = Math.floor((timeLeft / 60) % 60);
                const hour = Math.floor((timeLeft / 3600) % 24);
                const day = Math.floor(timeLeft / (3600 * 24));
                // console.log('计算剩余时间', day, hour, min, sec);
                const addZero = val => `${val}`.padStart(2, '0');
                // setCountdown(`${day} days ${addZero(hour)}:${addZero(min)}:${addZero(sec)}`);
                setBtnState(1);
            } else {
                // setCountdown(`0 days 00:00:00`);
                setCountdown([`00:00:00`, `0 days `]);
                if (poolInfo.nftNumber || poolInfo.stakingNumber) {
                    setBtnState(2);
                } else {
                    setBtnState(3);
                }
            }
        }
    }, [bcTime, poolInfo]);

    // useEffect(() => {
    //     console.log(poolInfo.takerThreshold);
    //     console.log(poolInfo.stakingNumber);
    //     setLeftdeposit(poolInfo.takerThreshold - poolInfo.stakingNumber);
    // }, [poolInfo.takerThreshold, poolInfo.stakingNumber]);

    const [loadingSubmit, setLoadingSubmit] = useState(false);
    // 提取奖励
    const withdrawAndClaim = async () => {
        // 判断是否为当前钱包链接地址
        const isSame = await accountIsSameAsCurrentWallet();
        if (!isSame) {
            message.error(
                'The wallet address bound by the current user is different from the connected wallet address. The request is aborted.',
            );
            return;
        }
        // console.log('withdraw');
        try {
            setLoadingSubmit(true);
            const from = userInfo.WalletAddress;
            devLog('withdrawAndClaim', currentPoolId, from);
            const res = await contractIStake.methods.withdrawAndClaim(currentPoolId).send({ from });
            // console.log('withdrawAndClaim', res);
            // showModal('withdraw success', '');
            showModal(
                'Successfully Extract',
                <div>
                    <div className={styles.gourp}>
                        <div className={styles.left}>
                            <div className={styles.label}>$FIRE Deposit</div>
                            <div className={styles.text}>
                                {formatNumber(poolInfo.stakingNumber)}
                            </div>
                        </div>
                        {/* todo 改为动态获取nft奖励 */}
                        {!!poolInfo.nftNumber && <div className={styles.divider}></div>}
                        {!!poolInfo.nftNumber && (
                            <div className={styles.right}>
                                {/* <div className={styles.label}>The loot box you will receive</div> */}
                                <div className={styles.text}>
                                    <img
                                        className={styles.nftImage}
                                        src={rewardsImage}
                                        alt={nftName}
                                    />
                                    ×{poolInfo.nftNumber}
                                </div>
                                <div className={styles.tips}>
                                    <ul style={{ listStyleImage: `url('${ulIcon}')` }}>
                                        <li>Your assets will be beam to your storage.</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>,
            );
            fetchData();
        } catch (error) {
            console.warn('handleStake error', error);
            if (error.message) {
                showModal('error', error.message);
            }
        } finally {
            setLoadingSubmit(false);
        }
    };

    let appBg, nftDesc, rewardsImage, poolName, panelBg, nftName;
    let tooltip1 = `The total loot boxes you have earned in this pool.`,
        tooltip2 = `The total tokens you have deposited in this pool.`;
    const tips = [
        <span>
            Deposit reward extraction time:{' '}
            <span className={styles.colorText}>{formatDate(poolInfo.endStakingTime)}</span>
        </span>,
    ];

    switch (currentPoolId) {
        case '1':
            appBg = appBg1;
            nftName = 'Premium Loot Box';
            nftDesc = `Premium loot box is a common loot box that contains NFT hero cards of all materials and
            rarities. There is a certain probability of dropping gold, silver and other special
            material cards.`;
            rewardsImage = standardLootBox;
            poolName = 'Standard Pool';
            panelBg = panelBg1;
            break;
        case '2':
            appBg = appBg2;
            nftName = 'Epic Loot Box';
            nftDesc = `Epic loot box is a precious loot box that contains NFT hero cards of all
            materials and rarities. There is a high probability of dropping gold, silver
            and other special material cards.`;
            rewardsImage = epicLootBox;
            poolName = 'Epic Pool';
            panelBg = panelBg2;
            break;
        case '3':
            appBg = appBg3;
            nftName = 'Legendary Loot Box';
            nftDesc = `Legendary loot box is an extremely valuable loot boxes with limited supply that contains
            NFT hero cards of all materials and rarities. There is a very high probability of
            dropping gold, silver and other special material cards.`;
            rewardsImage = legendaryLootBox;
            poolName = 'Legendary Pool';
            panelBg = panelBg3;
            break;
        default:
            break;
    }

    return (
        <div
            className={[styles.app, styles['pooltype' + currentPoolId]].join(' ')}
            style={{ backgroundImage: `url('${appBg}')` }}
        >
            <Nav />

            <div className={styles.header}>
                <div className={styles.poolInfos}>
                    <div className={styles.poolName}>
                        {poolName}
                        <div
                            className={styles.phase}
                            style={{ backgroundImage: `url('${phaseBg}')` }}
                        >
                            Phase 1
                        </div>
                    </div>
                    <div className={styles.poolTime}>
                        Deposit Start time: {formatDate(poolInfo.beginVaultTime)} --{' '}
                        {formatDate(poolInfo.endVaultTime)}
                    </div>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.totalInfos}>
                    <div className={styles.label}>Total $FIRE deposited in this pool</div>
                    <div className={styles.value}>
                        $FIRE {formatNumber(poolInfo.totalStakingTokens)}
                    </div>
                </div>
            </div>

            <div className={styles.flexBox}>
                <div className={styles.left}>
                    {/* <div className={styles.poolTitle}>{poolName}</div> */}
                    <img className={styles.nftImage} src={rewardsImage} alt={nftName} />
                    <div className={styles.nftName}>{nftName}</div>
                    <div className={styles.nftDesc}>{nftDesc}</div>
                </div>
                <Spin spinning={!poolInfo.poolId}>
                    <div className={styles.panel} style={{ backgroundImage: `url('${panelBg}')` }}>
                        <div className={styles.left}>
                            <div className={styles.colBox} style={{ width: '60%' }}>
                                <div>
                                    <div className={styles.blueText}>
                                        My Loot Boxes
                                        <Tooltip title={tooltip1} placement="bottomLeft">
                                            <img className={styles.tipsIcon} src={tipsIcon} />
                                        </Tooltip>
                                    </div>
                                    <div className={styles.valueText}>{poolInfo.nftNumber}</div>
                                </div>
                                <div className={styles.divider}></div>
                                <div>
                                    <div className={styles.blueText}>
                                        $FIRE Deposit
                                        <Tooltip title={tooltip2} placement="bottomLeft">
                                            <img className={styles.tipsIcon} src={tipsIcon} />
                                        </Tooltip>
                                    </div>
                                    <div className={styles.valueText}>
                                        {formatNumber(poolInfo.stakingNumber)}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.blueText}>Deposit reward extraction time</div>

                            <div className={styles.colBox}>
                                <div className={styles.dashbox}>
                                    <div className={styles.blueText} style={{ marginTop: '6px' }}>
                                        {/* {countdown} */}
                                        {countdown.map((item, index) => (
                                            <span key={index}>{item}</span>
                                        ))}
                                    </div>
                                </div>

                                <div className={styles.btnBox}>
                                    {btnState === 1 && (
                                        <div className={[styles.btn, styles.disabled].join(' ')}>
                                            Wait to extract
                                        </div>
                                    )}
                                    {btnState === 2 && (
                                        <Spin spinning={loadingSubmit}>
                                            <div className={styles.btn} onClick={withdrawAndClaim}>
                                                Withdraw and Extract
                                            </div>
                                        </Spin>
                                    )}
                                    {btnState === 3 && (
                                        <div className={[styles.btn, styles.disabled].join(' ')}>
                                            Closed
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={styles.tips}>
                                <div className={styles.label}>Tips:</div>
                                <ul style={{ listStyleImage: `url('${ulIcon}')` }}>
                                    {tips.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {/* <div className={styles.right}>
                            <div className={styles.rewardsImage}>
                                <img src={rewardsImage} alt="" />
                            </div>
                            <div className={styles.rewardsInfos}>
                                <div className={styles.label}>Staking NFT rewards</div>
                                <div className={styles.box}>
                                    Rare box
                                    <div className={styles.unit}></div>
                                </div>
                                <div className={styles.label}>Deposit time</div>
                                <div className={styles.box}>
                                    {formatDate(poolInfo.beginVaultTime)} to
                                    <br />
                                    {formatDate(poolInfo.endVaultTime)}
                                </div>
                                <div className={styles.label}>Pledge end time</div>
                                <div className={styles.box}>
                                    {formatDate(poolInfo.endStakingTime)}
                                </div>
                            </div>
                        </div> */}
                    </div>
                </Spin>
            </div>
            <div
                id="modal"
                className={styles.modalWrap}
                style={{ display: modalState.visibale ? 'flex' : 'none' }}
            >
                <div className={styles.modalBody} style={{ backgroundImage: `url('${modalBg}')` }}>
                    {/* <div className={styles.modalDecoration}></div> */}
                    <div className={styles.title}>
                        {modalState.title}
                        {/* 质押完成！ */}
                    </div>
                    <div className={styles.message}>
                        {modalState.message}
                        {/* 你可以在15:24:38/24/05/2022，前往质押详情页进行NFT奖励提取 */}
                    </div>
                    <div className={styles.btn} onClick={() => hideModal()}>
                        Confirm
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
