import styles from "./index.module.scss";
const Button = (prop) => {
  return (
    <div className={`${styles["spk-btn-ctx"]} ${prop.type === 'trap' && styles["spk-btn-trap"]}`}>
      {prop.type === "link" ? (
        <a
          href={prop.href}
          target={prop.target}
          className={`${styles["spk-btn"]}} ${prop.className}`}
        >
          {prop.children}
        </a>
      ) : (
        <div
          className={`${styles["spk-btn"]}  ${prop.className}`}
          onClick={() => {
            prop.onClick && prop.onClick();
          }}
        >
          {prop.children}
        </div>
      )}
    </div>
  );
};

export default Button;
