import React, { useEffect } from 'react';
import styles from './index.module.scss';
import betaNowImg from '../../assets/imgs/beta-now-bg.png';

const BetaNow = () => {
    useEffect(() => {
        document.title = 'beta-now';
    }, []);
    function downloadGames() {
        window.location.href =
            'https://beta.firework.games/download/GearupforWar_beta_installer.exe';
    }
    function downloadVrGames() {
        window.location.href =
            'https://beta.firework.games/download/GearupforWar_beta_VR_installer.exe';
    }

    function goToRegister() {
        window.location.href = '/register';
    }
    return (
        <div className={styles['beta-now']}>
            <div className={styles['beta-now-header']}>
                <h2>Spark Era: GEARUP FOR WARS </h2>
                <h3>The world's first 3D galactic PUBG-style,VR-ready blockchain game.</h3>
                <h3>Beta version is available now.</h3>
            </div>
            <div className={styles['beta-now-content']}>
                <img src={betaNowImg} width="100%" height="100%" alt="" />
            </div>
            <div className={styles['beta-now-btn']}>
                <button onClick={() => downloadGames()} className={styles['beta-now-download']}>
                    DOWNLOAD
                </button>
                <button onClick={() => downloadVrGames()} className={styles['beta-now-download']}>
                    DOWNLOAD VR
                </button>
                <a href="/register" className={styles['beta-now-register']}>
                    REGISTER
                </a>
            </div>
        </div>
    );
};

export default BetaNow;
