import styles from './loot.module.scss';
import { Link } from 'react-router-dom';
import ReactSlider from 'react-slider';
import RaceImage from '../../assets/imgs/people-1.png';
import SpaceshipImage from '../../assets/imgs/spaceship.png';
import TwImage from '../../assets/imgs/twitter.png';
import TelImage from '../../assets/imgs/telegram.png';
import DisImage from '../../assets/imgs/discord.png';
import { useState, useEffect, useRef } from 'react';

import MetaMaskOnboarding from '@metamask/onboarding';
import detectEthereumProvider from '@metamask/detect-provider';
import Modal from 'react-modal';
import { ethers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import lootAbi from './abi.json';

const TestnetPOLYGON = '0x13881';
const MainnetPOLYGON = '0x89';
console.log('process.env.REACT_APP_CHAIN_ENV', process.env.REACT_APP_CHAIN_ENV);
const isProd = process.env.REACT_APP_CHAIN_ENV === 'main';
// TODO 准备线上合约
const lootContractAddress = isProd
    ? '0x16b558DD9EB6E057B8f0E7D146F1DDc567538Ac9'
    : '0x17c881033A879F253d050b5f1cD38c19584024dd';

function replaceText(text, start, stop, replaceStr) {
    if (text) {
        return text.replace(text.substring(start, start + stop), replaceStr);
    } else {
        return '';
    }
}

function randomSplit(num, piece, minV, maxV) {
    const res = [];

    while (piece > 0) {
        const l = Math.max(minV, num - (piece - 1) * maxV);
        const r = Math.min(maxV, num - (piece - 1) * minV);
        const pieceNum = Math.floor(Math.random() * (r - l + 1) + l);
        piece--;
        num -= pieceNum;
        res.push(pieceNum);
    }
    return res;
}
const reg = /^[0-9]*[1-9][0-9]*$/;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
};

const Gender = ['MALE', 'FEMALE', 'NO GENDER'];
const Traits = [
    'TOUGH & BRAVE',
    'BLOODTHIRSTY RAGE',
    'MERCHANT',
    'WITTY & AGILE',
    'ALL-ROUNDER',
    'COWARD',
    'DREAMER',
    'RELIGIOUS FANATIC',
    'CHAMPIONS OF PEACE',
];
const Camp = ['Confederation of Earth', 'Osmen Empire', 'Eternal Guardian', 'Omota Empire'];
const Loot = () => {
    const [value1, setValue1] = useState(0);
    const [value2, setValue2] = useState(0);
    const [value3, setValue3] = useState(0);
    const [value4, setValue4] = useState(0);
    const [value5, setValue5] = useState(0);
    const [tokenNumber, setTokenNumber] = useState();
    const [point, sePoint] = useState(25);

    const [gender, setGender] = useState(0);
    const [traits, setTraits] = useState(0);
    const [camp, setCamp] = useState(0);

    const [account, setAccount] = useState();
    const [chainId, setChainId] = useState();
    const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);
    const [isTransferring, setIsTransferring] = useState(false);

    const [image, setImage] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);

    const provider = useRef(null);

    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };

    const onChange = (val, index) => {
        let currentPoint = 0;
        switch (index) {
            case 1:
                currentPoint = val + value2 + value3 + value4 + value5;
                break;
            case 2:
                currentPoint = value1 + val + value3 + value4 + value5;
                break;
            case 3:
                currentPoint = value1 + value2 + val + value4 + value5;
                break;
            case 4:
                currentPoint = value1 + value2 + value3 + val + value5;
                break;
            case 5:
                currentPoint = value1 + value2 + value3 + value4 + val;
                break;
            default:
                break;
        }
        if (currentPoint > 25) return;
        switch (index) {
            case 1:
                setValue1(val);
                break;
            case 2:
                setValue2(val);
                break;
            case 3:
                setValue3(val);
                break;
            case 4:
                setValue4(val);
                break;
            case 5:
                setValue5(val);
                break;
            default:
                break;
        }
    };

    const count = () => {
        const currentPoint = 25 - (value1 + value2 + value3 + value4 + value5);
        sePoint(currentPoint);
    };

    const randomHandler = () => {
        const values = randomSplit(25, 5, 0, 10);
        setValue1(values[0]);
        setValue2(values[1]);
        setValue3(values[2]);
        setValue4(values[3]);
        setValue5(values[4]);
    };

    useEffect(() => {
        count();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value1, value2, value3, value4, value5]);

    const startApp = () => {
        // eslint-disable-next-line no-undef
        // ethereum.on('chainChanged', chainId => {
        //     console.log('chainId', chainId);
        //     window.location.reload();
        // });
        // eslint-disable-next-line no-undef
        // ethereum.on('accountsChanged', accounts => {
        //     console.log('accounts', accounts);
        //     window.location.reload();
        // });
    };

    const requestAccounts = async () => {
        // eslint-disable-next-line no-undef
        const accounts = await ethereum.request({
            method: 'eth_requestAccounts',
        });
        // eslint-disable-next-line no-undef
        const chainId = await ethereum.request({
            method: 'eth_chainId',
        });
        setAccount(accounts[0]);
        setChainId(chainId);
    };
    const claimHandler = () => {
        if (!isMetaMaskInstalled) {
            toast.error('You need to install MetaMask!', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (chainId !== TestnetPOLYGON && chainId !== MainnetPOLYGON) {
            toast.error("We don't support this chain!", {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!reg.test(tokenNumber)) {
            toast.error('Token Number needs to be a number!', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (!reg.test(tokenNumber)) {
            toast.error('Token Number needs to be a number!', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (tokenNumber < 1 || tokenNumber > 49000) {
            toast.error('Please enter a valid number!', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        bugHandler();
    };

    const bugHandler = async () => {
        try {
            if (isTransferring) return;
            if (!provider.current) {
                const currentProvider = await detectEthereumProvider();
                provider.current = new ethers.providers.Web3Provider(currentProvider);
            }
            const signer = provider.current.getSigner();

            const lootContract = new ethers.Contract(lootContractAddress, lootAbi, signer);

            let res = await lootContract.isTokenExist(tokenNumber);

            if (res) {
                toast.error('The token number is existed!', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }

            setIsTransferring(true);
            const options = { gasLimit: 250000 };
            const tx = await lootContract.claim(tokenNumber, gender, options);

            await toast.promise(tx.wait(), {
                position: 'top-center',
                pending: 'Claiming!',
                success: 'Claim successful!',
                error: 'Claim failed!',
            });

            const response = await lootContract.tokenURI(tokenNumber);
            console.log('🚀 ~ file: loot.jsx ~ line 244 ~ bugHandler ~ res', response);

            const json = JSON.parse(atob(response.split(',')[1]));
            setImage(json.image);
            openModal();

            setIsTransferring(false);
        } catch (error) {
            console.log(error);
            setIsTransferring(false);
            toast.error(error.message, {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const setValue = e => {
        setTokenNumber(+e.target.value);
    };
    useEffect(() => {
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            setIsMetaMaskInstalled(true);
            startApp();
            requestAccounts();
        } else {
            setIsMetaMaskInstalled(false);
        }
    }, []);
    return (
        <div className={styles.app}>
            <ToastContainer />
            <div className={styles.nav}>
                <Link className={styles.logo} to="/"></Link>
                {!isProd ? (
                    <div className={`${styles.button} ${styles.mb16}`}>TestnetPOLYGON</div>
                ) : null}
                <div className={`${styles.button} ${styles.mb16}`}>
                    {account ? replaceText(account, 9, 26, '...') : 'UNLOCK WALLET'}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.left}>
                    <h1 className={styles.h1}>LOOT CLAIM</h1>
                    <p className={styles.word}>
                        In the Cast Workshop of Spark Era, you can create your interstellar citizen
                        image, choose your race camp, identify your basic nature and vocation.
                        <br />
                        These will be automatically generated at random by smart contract. You can
                        create your own legend.
                    </p>
                    <div className={styles.modules}>
                        <img className={styles.image1} src={RaceImage} alt="Race" />
                        <img className={styles.image2} src={SpaceshipImage} alt="Spaceship" />
                    </div>
                    <h2 className={styles.h2}>What are Star Citizen and Loot for Spark Era？</h2>
                    <p className={styles.word}>
                        Star Citizen refers to the role of interstellar citizen created in
                        Metaverse.
                        <br />
                        Loot for Spark Era refers to the randomly-generated NFT assets of the smart
                        contract according to your Star Citizen role.
                        <br />
                        It will be converted into starships in the future.
                    </p>
                    <h2 className={`${styles.h2} ${styles.h2MT}`}>
                        What’s the difference between Loot for Spark Era and other on-chain loot？
                    </h2>
                    <p className={`${styles.word} ${styles.wordColor}`}>
                        What you create is what you possess. Your cast won’t be wasted and your
                        expectations won’t be in vain！The Loot for Spark Era created at random will
                        be allowed to be mapped in the main game network at the end of third quarter
                        of 2021. Convert a starship featured with both gaming and economic profits.
                        <br />
                        According to different natures of Star Citizen images created in Spark Era
                        Cast Workshop, players are allowed to own their own Loot for Spark Era
                        created by the system. Possibly, you will have a unique super starship！
                    </p>
                </div>
                <div className={styles.right}>
                    <h3 className={styles.h3}>START YOUR CHOICE:</h3>
                    <div className={styles.content}>
                        <div className={styles.contentItem}>
                            {/* GENDER */}
                            <div className={`${styles.item} ${styles.itemBM}`}>
                                <div className={styles.label}>
                                    <div className={styles.labelText}>GENDER</div>
                                </div>
                                <div className={styles.list}>
                                    {Gender.map((item, index) => (
                                        <div
                                            className={`${styles.listItem} ${styles.listItemML40} ${
                                                gender === index ? styles.listItemActive : ''
                                            }`}
                                            key={index}
                                            onClick={() => setGender(index)}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* Traits */}
                            <div className={`${styles.item} ${styles.itemBM}`}>
                                <div className={styles.label}>
                                    <div className={styles.labelText}>TRAITS</div>
                                </div>
                                <div className={styles.list}>
                                    {Traits.map((item, index) => (
                                        <div
                                            className={`${styles.listItem} ${styles.listItemML20} ${
                                                traits === index ? styles.listItemActive : ''
                                            }`}
                                            key={index}
                                            onClick={() => setTraits(index)}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* Camp */}
                            <div className={`${styles.item}`}>
                                <div className={styles.label}>
                                    <div className={styles.labelText}>CAMP</div>
                                </div>
                                <div className={styles.list}>
                                    {Camp.map((item, index) => (
                                        <div
                                            className={styles.listItemBox}
                                            key={index}
                                            onClick={() => setCamp(index)}
                                        >
                                            <div
                                                className={`${styles.listItemIcon} ${
                                                    styles[`listItemIcon${index + 1}`]
                                                }  ${
                                                    camp === index ? styles.listItemIconActive : ''
                                                }`}
                                            ></div>
                                            <div
                                                className={`${styles.listItem} ${
                                                    camp === index ? styles.listItemActive : ''
                                                }`}
                                            >
                                                {item}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={styles.contentItem}>
                            <div className={`${styles.item} ${styles.itemBM2}`}>
                                <div className={styles.label} style={{ marginBottom: 8 }}>
                                    <div className={styles.labelText}>ATTRIBUTES</div>
                                </div>
                                <div className={styles.point}>
                                    AVAILABLE POINTS
                                    <span className={styles.currentPoint}>{point}</span>
                                </div>
                                <div className={styles.slider}>
                                    <div className={`${styles.sliderItem} ${styles.sliderItem1}`}>
                                        <div className={styles.sliderItemHd}></div>
                                        <div className={styles.sliderItemBd}>PHYSICAL STRENGTH</div>
                                        <div className={styles.sliderItemFt}>
                                            <div className={styles.t}></div>
                                            <ReactSlider
                                                className="horizontalSlider"
                                                thumbClassName="thumb"
                                                trackClassName="track"
                                                value={value1}
                                                onChange={val => onChange(val, 1)}
                                                max={10}
                                            />
                                            <div className={styles.b}>
                                                <span className={value1 > 0 ? styles.white : ''}>
                                                    {value1}/
                                                </span>
                                                <span>10</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.sliderItem} ${styles.sliderItem2}`}>
                                        <div className={styles.sliderItemHd}></div>
                                        <div className={styles.sliderItemBd}>STRENGTH</div>
                                        <div className={styles.sliderItemFt}>
                                            <div className={styles.t}></div>
                                            <ReactSlider
                                                className="horizontalSlider"
                                                thumbClassName="thumb"
                                                trackClassName="track"
                                                value={value2}
                                                onChange={val => onChange(val, 2)}
                                                max={10}
                                            />
                                            <div className={styles.b}>
                                                <span className={value2 > 0 ? styles.white : ''}>
                                                    {value2}/
                                                </span>
                                                <span>10</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.sliderItem} ${styles.sliderItem3}`}>
                                        <div className={styles.sliderItemHd}></div>
                                        <div className={styles.sliderItemBd}>CHARM</div>
                                        <div className={styles.sliderItemFt}>
                                            <div className={styles.t}></div>
                                            <ReactSlider
                                                className="horizontalSlider"
                                                thumbClassName="thumb"
                                                trackClassName="track"
                                                value={value3}
                                                onChange={val => onChange(val, 3)}
                                                max={10}
                                            />
                                            <div className={styles.b}>
                                                <span className={value3 > 0 ? styles.white : ''}>
                                                    {value3}/
                                                </span>
                                                <span>10</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.sliderItem} ${styles.sliderItem4}`}>
                                        <div className={styles.sliderItemHd}></div>
                                        <div className={styles.sliderItemBd}>AGILITY</div>
                                        <div className={styles.sliderItemFt}>
                                            <div className={styles.t}></div>
                                            <ReactSlider
                                                className="horizontalSlider"
                                                thumbClassName="thumb"
                                                trackClassName="track"
                                                value={value4}
                                                onChange={val => onChange(val, 4)}
                                                max={10}
                                            />
                                            <div className={styles.b}>
                                                <span className={value4 > 0 ? styles.white : ''}>
                                                    {value4}/
                                                </span>
                                                <span>10</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.sliderItem} ${styles.sliderItem5}`}>
                                        <div className={styles.sliderItemHd}></div>
                                        <div className={styles.sliderItemBd}>INTELLIGENCE</div>
                                        <div className={styles.sliderItemFt}>
                                            <div className={styles.t}></div>
                                            <ReactSlider
                                                className="horizontalSlider"
                                                thumbClassName="thumb"
                                                trackClassName="track"
                                                value={value5}
                                                onChange={val => onChange(val, 5)}
                                                max={10}
                                            />
                                            <div className={styles.b}>
                                                <span className={value5 > 0 ? styles.white : ''}>
                                                    {value5}/
                                                </span>
                                                <span>10</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.button} onClick={randomHandler}>
                                    random
                                </div>
                            </div>
                            <div className={`${styles.item}`}>
                                <div className={`${styles.label} ${styles.labelBtn}`}>
                                    <div className={styles.labelText}>TOKEN NUMBER</div>
                                </div>
                                <input
                                    className={styles.input}
                                    placeholder="ENTER YOUR NUMBER"
                                    onChange={setValue}
                                />
                                <p className={styles.tip}>Token Number is between 1 to 49000</p>
                                <p className={`${styles.word} ${styles.wordColor}`}>
                                    <a href="https://twitter.com/SparkeraGame" target="_blank">
                                        Missed our Loot claim? Click here for upcoming events!
                                        <br />{' '}
                                    </a>
                                </p>
                                <div className={styles.button} /*onClick={claimHandler}*/>
                                    CLAIM IS OVER
                                </div>
                                <p className={styles.tip}>Powered by Polygon</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={` ${styles.sliderItembot}`}>
                <p className={`${styles.word} `}> follow us</p>
                <div>
                    <a href="https://twitter.com/SparkeraGame">
                        <img
                            className={styles.twlogo}
                            src={TwImage}
                            alt="Twitter"
                            title="Twitter"
                        />
                    </a>
                </div>
                <div>
                    <a href="http://t.me/FireworkGames_">
                        <img
                            className={styles.tglogo}
                            src={TelImage}
                            alt="Telgram"
                            title="Telgram"
                        />
                    </a>
                </div>
                <div>
                    <a href=" http://discord.com/invite/Hyanu49">
                        <img
                            className={styles.tglogo}
                            src={DisImage}
                            alt="Discord"
                            title="Discord"
                        />
                    </a>
                </div>
            </div>
            <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
                <div className={styles.modalChoseBtn} onClick={closeModal}>
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        alt="times-hexagon"
                    >
                        <path
                            fill="currentColor"
                            d="M553.5 231.8c8.7 14.9 8.7 33.4 0 48.4l-112 192c-8.6 14.7-24.4 23.8-41.5 23.8H176c-17.1 0-32.9-9.1-41.5-23.8l-112-192c-8.7-14.9-8.7-33.4 0-48.4l112-192C143.1 25.1 158.9 16 176 16h224c17.1 0 32.9 9.1 41.5 23.8l112 192zM409.6 338c4.7-4.7 4.7-12.3 0-17l-65-65 65.1-65.1c4.7-4.7 4.7-12.3 0-17L370 134.4c-4.7-4.7-12.3-4.7-17 0l-65 65-65.1-65.1c-4.7-4.7-12.3-4.7-17 0L166.4 174c-4.7 4.7-4.7 12.3 0 17l65.1 65.1-65.1 65.1c-4.7 4.7-4.7 12.3 0 17l39.6 39.6c4.7 4.7 12.3 4.7 17 0l65.1-65.1 65.1 65.1c4.7 4.7 12.3 4.7 17 0l39.4-39.8z"
                        ></path>
                    </svg>
                </div>
                <div className={styles.modalContent}>
                    <img src={image} alt="NFT Image" />
                    {/* <p className={styles.opensea}>
                        <a href="https://opensea.io/collection/loot-for-spark-era-lse">
                            Congratulations,You claim a SparkEra spaceship NFT and can trade on
                            Opensea.
                        </a>
                    </p> */}
                </div>
            </Modal>
        </div>
    );
};

export default Loot;
