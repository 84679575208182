import Index from '../views/index/index';
import Loot from '../views/loot/loot';
import Guide from '../views/guide';
import Halloween from '../views/halloween/index';
import LatestNews from '../views/latestnews/index';
import NewsList from '../views/newsList/index';
// import Register from '../views/register/index';
// import MobileRegister from '../views/register/mobile';
import BetaNow from '../views/beta-now/index';
// import ForgetPwd from '../views/forgetpwd/index';
import Stake from '../views/stake/index';
import StakePage from '../views/stake/page';
import StakeRewards from '../views/stake/rewards';
import Invitation from '../views/invitation/index';
import Game from '../views/game/index';
import GameV3 from '../views/game/v3';
import GameV2 from '../views/game/v2';
import TransitionWithVouchers from '../views/transition/index';

const routesConfig = [
    {
        path: '/',
        component: Index,
        exact: true,
    },
    {
        path: '/Loot',
        component: Loot,
        exact: true,
    },
    {
        path: '/Guide',
        component: Guide,
        exact: true,
    },
    {
        path: '/SparkEra/Loot',
        component: Loot,
        exact: true,
    },
    /*
    {
        path: '/halloween',
        component: Halloween,
        exact: true,
    },*/
    // {
    //     path: '/register',
    //     component: Register,
    //     exact: true,
    // },
    // {
    //     path: '/mobile-register',
    //     component: MobileRegister,
    //     exact: true,
    // },
    {
        path: '/beta-now',
        component: BetaNow,
        exact: true,
    },
    {
        path: '/game',
        component: Game,
        exact: true,
    },
    {
        path: '/game/v3',
        component: GameV3,
        exact: true,
    },
    {
        path: '/game/v2',
        component: GameV2,
        exact: true,
    },
    // {
    //     path: '/forget-pwd',
    //     component: ForgetPwd,
    //     exact: true,
    // },
    {
        path: '/LatestNews/:id',
        component: LatestNews,
        exact: true,
    },
    {
        path: '/newsList',
        component: NewsList,
        exact: true,
    },
    // {
    //     path: '/news/:id',
    //     component: news,
    // },
    // {
    //     path: '/news',
    //     component: news,
    // },
    {
        path: '/loyalty',
        component: Stake,
        exact: true,
    },
    {
        path: '/loyalty-page',
        component: StakePage,
        exact: true,
    },
    {
        path: '/loyalty-rewards',
        component: StakeRewards,
        exact: true,
    },
    {
        // 如果修改这里的path，需要一并修改/views/invitation/index.jsx中181行的path
        path: '/invite',
        component: Invitation,
        exact: true,
    },
    {
        path: '/TransitionWithVouchers',
        component: TransitionWithVouchers,
        exact: true,
    },
];

export { routesConfig };
