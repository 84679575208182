import MetaMaskOnboarding from "@metamask/onboarding";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import PreSaleBoxAbi from "./HSE.json";
import HalloAbi from "./HalloweenBlackSmith.json";
import FireworkAbi from "./FireworkCard.json";

// HalloweenBlackSmith
// 0x74637e30350C20965c6168Bc69460db782A1fE88

// FireworkCard
// 0x8b1222d248C598074544C0E4507ae3D1d788008E
//
const deployContractAddress = "0xeC2152E56dB8F392eF3E171BC3eDBd33c0188410"; // HSE 合约
const HalloweenBlackSmith = "0x9b71087CBCeb5d0A6c2685D0F40f3e586b9A7D02";
const FireworkCard = "0xe28647ac592E5CC81Eca240DF3923B19b6196182";

class BoxService {
  provider = null;
  signer = null;
  currentAcc = "";
  fwCardContract = "";
  halloContract = "";

  hasInstall = async () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      this.startApp();
      await this.getCurrentAcc();
      return true;
    }
    return false;
  };

  downloadMetaMask = () => {
    const onboarding = new MetaMaskOnboarding();
    onboarding.startOnboarding();
  };

  getProvider = async () => {
    if (this.provider && this.signer) {
      return {
        provider: this.provider,
        signer: this.signer,
      };
    }
    let currentProvider = await detectEthereumProvider();
    this.provider = new ethers.providers.Web3Provider(currentProvider);
    this.signer = this.provider.getSigner();

    return {
      provider: this.provider,
      signer: this.signer,
    };
  };

  createContract = () => {
    if (!this.preSaleContract) {
      this.preSaleContract = new ethers.Contract(
        deployContractAddress,
        PreSaleBoxAbi.abi,
        this.signer
      );
    }
  };
  createHalloContract = () => {
    if (!this.halloContract) {
      this.halloContract = new ethers.Contract(
        HalloweenBlackSmith,
        HalloAbi.abi,
        this.signer
      );
    }
  };
  createFwContract = () => {
    if (!this.fwCardContract) {
      this.fwCardContract = new ethers.Contract(
        FireworkCard,
        FireworkAbi.abi,
        this.signer
      );
    }
  };
  startApp = () => {
    // eslint-disable-next-line
    ethereum.on("chainChanged", (chainId) => {
      console.log("chainId", chainId);
      window.location.reload();
    });
    // eslint-disable-next-line
    ethereum.on("accountsChanged", (accounts) => {
      console.log("accounts", accounts);
      window.location.reload();
    });
  };

  getChainId  = async () => {
    // eslint-disable-next-line
    const chainId = await ethereum.request({
      method: 'eth_chainId',});
    return chainId;
  };

  getCurrentAcc = async () => {

    // eslint-disable-next-line
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    console.log("account", accounts);
    this.currentAcc = accounts[0];
    return accounts[0];
  };

  /**
   * 查询最大供应量
   * @returns
   */
  getMaxSupply = async () => {
    try {
      await this.getProvider();
      this.createContract();
      const tx = await this.preSaleContract.getMaxSupply();
      return tx.toNumber()
    } catch (error) {
      console.log("get max-supply error", error);
      return 0;
    }
  }
  /**
   * 查询已售出量
   * @returns
   */
  getTotalSupply = async () => {
    try {
      await this.getProvider();
      this.createContract();
      const tx = await this.preSaleContract.totalSupply();
      return tx.toNumber();
    } catch (error) {
      console.log("get max-supply error", error);
      return 0;
    }
  }

  /**
   * 查询已经购买箱子的数量
   * 返回顺序要跟箱子保持一样
   * @returns
   */
  getBoxBalance = async () => {
    try {
      await this.getProvider();
      this.createContract();

      const res = await Promise.all([
        this.preSaleContract.queryMyStandardBoxBalance(),
        this.preSaleContract.queryMyAdvancedBoxBalance(),
        this.preSaleContract.queryMyPreciousBoxBalance(),
        this.preSaleContract.queryMyLegendaryBoxBalance(),
      ]);
      return res.map((res) => res.toNumber());
    } catch (error) {
      console.log("get box-balance error", error);
      return [0, 0, 0, 0];
    }
  };


  buyHalloBox = (number) => {
    // value: ethers.BigNumber.from(10).pow(17),
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {

      try {
        await this.getProvider();
        this.createContract();
        const tx = await this.preSaleContract.hseClaim(number, {
          value: ethers.BigNumber.from(10).pow(17).mul(number)
        })
        await tx.wait();
        resolve();
      } catch (error) {
        console.log("buy box error", error);
        reject(error);
      }
    });
  }

  /**
   * 第一步 获取箱子数量
   * @returns
   */
  getHalloBalance = () => {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        await this.getProvider();
        this.createContract();
        console.log(this.currentAcc);
        const tx = await this.preSaleContract.balanceOf(this.currentAcc)
        // await id.wait();
        resolve(tx.toNumber());
      } catch (error) {
        console.log("getHalloBalance box error", error);
        reject(error);
      }
    });
  }
  /**
   * 第二步 获取箱子 HSE ID 开单个箱子需要用
   * @returns
   */
  getTokenIndex = () => {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        await this.getProvider();
        this.createContract();
        const tx = await this.preSaleContract.tokenOfOwnerByIndex(this.currentAcc, 0);
        console.log(tx)
        resolve(tx);
      } catch (error) {
        console.log("getHalloBalance box error", error);
        reject(error);
      }
    });
  }

    /**
   * 第三步
   * 开箱第一步
   * 1. 先调用HSE的setApprovalForAll方法，传 HalloweenBlackSmith合约地址和true
   * @returns
   */
  setApproval = () => {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        await this.getProvider();
        this.createContract();
        const tx = await this.preSaleContract.setApprovalForAll(HalloweenBlackSmith, true);
        console.log('approval', tx)
        await tx.wait();
        resolve();
      } catch (error) {
        console.log("getFwApproval box error", error);
        reject(error);
      }
    });
  }
  /**
   * 判断是否 setApproval 返回true就无需调用 setApproval
   * @returns
   */
  isApproved = () => {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        await this.getProvider();
        this.createContract();
        const tx = await this.preSaleContract.isApprovedForAll(this.currentAcc, HalloweenBlackSmith);
        console.log('isApproved', tx)
        resolve(tx);
      } catch (error) {
        console.log("getFwApproval box error", error);
        reject(error);
      }
    });
  }
   /**
   * 打开单个箱子
   * @returns
   */
  openHalloBox = (hseId) => {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        await this.getProvider();
        this.createHalloContract();
        const tx = await this.halloContract.forge(hseId, {
          gasLimit: 350000 // 燃料限制
        })
        await tx.wait();
        resolve();
      } catch (error) {
        console.log("openHalloBox box error", error);
        reject(error);
      }
    });
  }
  /**
   * 打开全部箱子
   * @returns
   */
  openHalloBoxAll = () => {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        await this.getProvider();
        this.createHalloContract();
        const tx = await this.halloContract.forgeAll({
          gasLimit: 3000000 // 燃料限制
        })
        await tx.wait();
        resolve();
      } catch (error) {
        console.log("openHalloBoxAll box error", error);
        reject(error);
      }
    });
  }

  /**
   * 调用 fireworkcard合约获取nftId
   * @returns
   */
  getFwNftId = () => {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        await this.getProvider();
        this.createFwContract();
        const arr = await this.fwCardContract.getAllTokenIdByAddress(this.currentAcc);
        resolve(arr);
      } catch (error) {
        console.log("openHalloBoxAll box error", error);
        reject(error);
      }
    });
  }
  getTokenURI = (id) => {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        await this.getProvider();
        this.createFwContract();
        const tx = await this.fwCardContract.tokenURI(id);
        resolve(tx);
      } catch (error) {
        console.log("openHalloBoxAll box error", error);
        reject(error);
      }
    });
  }
}

const A = () => <></>;
export default A;

const boxService = new BoxService();
export { boxService };
