import React from 'react';
import styles from './v2.module.scss';
import Nav from '../../components/Nav';
import { Carousel } from 'antd';
import 'antd/es/carousel/style/css';
import './carousel.css';
import bg1 from '../../assets/imgs/game/v2-bg1.png';

const Game = () => {
    return (
        <div className={styles.app}>
            <Nav />
            <div className={styles.content}>
                <Carousel>
                    <div>
                        <div
                            className={styles.contentStyle}
                            style={{ backgroundImage: `url('${bg1}')` }}
                        >
                            <div className={styles.title}>Luaer Journey</div>
                            <div className={styles.subTitle}>
                                Luaer Journey will be officially launched on January 8.
                                <br />
                                Kupgrade your crew, and set out for the boundless universe!
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className={styles.contentStyle}
                            style={{ backgroundImage: `url('${bg1}')` }}
                        >
                            <div className={styles.title}>Luaer Journey</div>
                            <div className={styles.subTitle}>
                                Luaer Journey will be officially launched on January 8.
                                <br />
                                Kupgrade your crew, and set out for the boundless universe!
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className={styles.contentStyle}
                            style={{ backgroundImage: `url('${bg1}')` }}
                        >
                            <div className={styles.title}>Luaer Journey</div>
                            <div className={styles.subTitle}>
                                Luaer Journey will be officially launched on January 8.
                                <br />
                                Kupgrade your crew, and set out for the boundless universe!
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className={styles.contentStyle}
                            style={{ backgroundImage: `url('${bg1}')` }}
                        >
                            <div className={styles.title}>Luaer Journey</div>
                            <div className={styles.subTitle}>
                                Luaer Journey will be officially launched on January 8.
                                <br />
                                Kupgrade your crew, and set out for the boundless universe!
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    );
};

export default Game;
