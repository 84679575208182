import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import bgVideo from '../../assets/video/bg-video.mp4';
import Nav from '../../components/Nav';
import Button from '../../components/Button';
import Modal from 'react-modal';
import AOS from 'aos';
import { isMobile } from '../../utils';
import { loadScript, removeScript } from '../../utils/index';
import { ToastContainer, toast } from 'react-toastify';
import 'aos/dist/aos.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '92%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: 'none',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
};

class Index extends React.Component {
    componentDidMount() {
        AOS.init({
            duration: 1200,
            easing: 'ease-out',
            delay: 200,
        });
        // ./js/currency.js
        // window.location.reload(true);
        loadScript('./js/currency.js');
    }
    componentWillUnmount() {
        // removeScript('./js/currency.js');
    }

    render() {
        const partnerLists = [
            {
                image: 'ac-capital.png',
                url: 'https://accapital.io/',
            },
            {
                image: 'alpha-crypto-capital.png',
                url: 'https://alphacrypto.capital/',
                size: '60%',
            },
            {
                image: 'vcathena.png',
                url: 'https://vcathena.com/',
            },
            {
                image: 'WealthUnion.jpeg',
                url: 'https://www.wealthunion.io/',
            },
            {
                image: 'redhat.png',
                url: 'https://redhatcapital.io/',
                size: '60%',
            },
            {
                image: 'genblock.png',
                url: 'https://genblock.capital/portfolio',
            },
            {
                image: 'shima.png',
                url: 'https://shima.capital/',
            },
            {
                image: 'ggg.png',
                url: 'https://goodgamesguild.com/',
            },
            {
                image: 'iq-space.svg',
                url: 'https://iq.space/',
            },

            {
                image: 'stakely.svg',
                url: 'https://stakely.io/',
            },
            {
                image: 'enjin.png',
                url: 'https://enjinstarter.com/',
            },
            {
                image: 'oig-capital.svg',
                url: 'https://oig.capital/',
                size: '60%',
            },
            {
                image: 'cspdao-logo-header.svg',
                url: 'https://cspdao.network/',
            },
            {
                image: 'lvt-captial.png',
                url: 'https://lvtcapital.com/',
            },

            {
                image: 'polygon.png',
                url: 'https://polygon.technology/',
            },
            {
                image: 'chainlink.png',
                url: 'https://www.chainlink-china.com/',
            },
            // {
            //     image: 'gate.png',
            //     url: 'https://www.gate.io/',
            // },
            // {
            //     image: 'kucoin.png',
            //     url: 'https://www.kucoin.com/',
            // },
            {
                image: 'unity.png',
                url: 'https://unity.com/',
            },
            {
                image: 'anmoka.png',
                url: 'https://www.animocabrands.com/',
            },
            {
                image: 'b21-capital.png',
                url: 'https://b21.capital/',
            },
            // {
            //     image: 'binance.png',
            //     url: 'https://www.binance.com/en',
            // },
            // {
            //     image: 'bsc.png',
            //     url: 'https://www.binance.org/en/smartChain',
            // },
        ];
        const iconLists = [
            {
                name: 'youtube',
                url: 'https://www.youtube.com/channel/UCqlMta2eYZjVXek6wQSt4Fg',
                position: '0 0',
            },
            // {
            //     name: 'fb',
            //     url: ':https://www.facebook.com/Spark-Era-105523978672884/',
            //     position: '-5.7vw 0',
            // },
            // {
            //     name: 'ins',
            //     url: 'https://www.instagram.com/sparkera_official/',
            //     position: '-10.2vw 0',
            // },
            { name: 'twitter', url: 'https://twitter.com/SparkeraGame', position: '-15.5vw 0' },
            { name: 'tel', url: 'https://t.me/FireworkGames_group', position: '-20.6vw 0' },
            // { name: 'in', url: '', position: '-26.6vw 0' },
            { name: 'cic', url: 'https://fireworkgames.medium.com/', position: '-31.5vw 0' },
            { name: 'mail', url: 'mailto:Official@Firework.games', position: '-39.4vw 0' },
        ];
        const avatarLists = [
            [
                // 7 + 7
                {
                    image: '1.png',
                },
                { image: '2.png' },
                { image: '3.png' },
                { image: '4.png' },
                { image: '5.png' },
                { image: '8.png' },
                { image: '11.png' },
                {
                    image: '1.png',
                },
                { image: '2.png' },
                { image: '3.png' },
                { image: '4.png' },
                { image: '5.png' },
                { image: '8.png' },
                { image: '11.png' },
            ],
            [
                {
                    image: '9.png',
                },
                { image: '10.png' },
                { image: '11.png' },
                { image: '6.png' },
                { image: '7.png' },
                { image: '19.png' },
                { image: '18.png' },
                {
                    image: '9.png',
                },
                { image: '10.png' },
                { image: '11.png' },
                { image: '6.png' },
                { image: '7.png' },
                { image: '19.png' },
                { image: '18.png' },
            ],
            [
                {
                    image: '14.png',
                },
                { image: '21.png' },
                { image: '22.png' },
                { image: '20.png' },
                { image: '16.png' },
                { image: '13.png' },
                { image: '3.png' },
                {
                    image: '14.png',
                },
                { image: '21.png' },
                { image: '22.png' },
                { image: '20.png' },
                { image: '16.png' },
                { image: '13.png' },
                { image: '3.png' },
            ],
            [
                {
                    image: '21.png',
                },
                { image: '1.png' },
                { image: '3.png' },
                { image: '10.png' },
                { image: '4.png' },
                { image: '2.png' },
                { image: '7.png' },
                {
                    image: '21.png',
                },
                { image: '1.png' },
                { image: '3.png' },
                { image: '10.png' },
                { image: '4.png' },
                { image: '2.png' },
                { image: '7.png' },
            ],
        ];
        return (
            <div className={styles.app}>
                {/* nav */}
                <Nav />
                <section className={styles.main}>
                    <header className={styles.mainTitle} data-aos="fade-right">
                        <p>REAL METAVERSE GAMING WORLD GUIDED</p>
                        <p>BY DARK FOREST LAW</p>
                        {/* <ClipButton
						text="PLAY FOR FREE"
						type="link"
						/> */}
                        <Button
                            style={{
                                // position: 're'
                                zIndex: 12,
                            }}
                            type="trap"
                            onClick={() => {
                                window.open('https://meta.firework.games/');
                            }}
                        >
                            <span>JOIN THE METAVERSE NOW</span>
                            {/* <span>Expected to go live on February 1, 2022</span> */}
                        </Button>
                    </header>
                    <video className={styles.video} loop autoPlay muted>
                        <source src={bgVideo} type="video/mp4" />
                    </video>
                    <div className={styles.mainBottomFade}></div>
                </section>

                <section className={styles.news}>
                    <header>
                        <span>LATEST NEWS</span>
                        <a href="#">View all</a>
                    </header>
                    <div className={styles.newsList}>
                        <a
                            href="https://supply.firework.games/game"
                            target="_blank"
                            style={{ width: '100%' }}
                        >
                            <img
                                src={require('../../assets/imgs/haibao/artboard1.png').default}
                                width="100%"
                                alt=""
                            />
                        </a>
                        <a
                            href="https://www.digitaljournal.com/pr/firework-games-announces-it-has-partnered-with-the-nvidia-inception-program-for-tech-startups"
                            target="_blank"
                            style={{ width: '100%' }}
                        >
                            <img
                                src={require('../../assets/imgs/haibao/artboard2.png').default}
                                width="100%"
                                alt=""
                            />
                        </a>
                        <a
                            href="https://fireworkgames.medium.com/brian-d-evans-spark-eras-strategic-advisor-will-take-the-stage-5b44f45c3f27"
                            target="_blank"
                            style={{ width: '100%' }}
                        >
                            <img
                                src={require('../../assets/imgs/haibao/artboard3.png').default}
                                width="100%"
                                alt=""
                            />
                        </a>
                    </div>
                </section>
                <section className={styles.spaceStage}>
                    <article>
                        <header data-aos="fade-up">
                            <h1>JOIN THE</h1>
                            <h2>LEGENDARY INTERSTELLAR WORLD IMMEDIATELY</h2>
                        </header>
                        <div className={styles.contain}>
                            <p>
                                We look into the deep space, chase the galaxy, and spare no effort
                                to explore the unknown. in Spark Era, Play as one of the four major
                                races and write a legendary history.
                            </p>
                        </div>
                    </article>
                </section>
                <section className={styles.charStage}>
                    <article>
                        <header data-aos="fade-up">
                            <h1>WHO WILL YOU</h1>
                            <h2>CHOSE?</h2>
                        </header>
                        <p>
                            As a role-playing interstellar meta-universe game, players can choose
                            from many roles, ranging from magical psionicists to cruel swarm empires
                            and tough earth federations. The hero pool of Spark Era is huge and
                            infinitely diverse. Unleash incredible abilit
                        </p>
                    </article>
                </section>
                <section className={styles.swipperStage}>
                    {avatarLists.map((item, index) => (
                        <ul key={index}>
                            {item.map((item_, index_) => (
                                <li key={`${index}-${index_}`}>
                                    <a
                                        style={{
                                            backgroundImage:
                                                'url(' +
                                                require(`../../assets/imgs/role/${item_.image}`)
                                                    .default,
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>
                    ))}
                </section>
                <section className={styles.guideStage}>
                    <article>
                        <div className={styles.text}>
                            <header data-aos="fade-up">
                                <h1>HOW TO PLAY?</h1>
                            </header>
                            <p>
                                As a role-playing interstellar meta-universe game, players can
                                choose from many roles, ranging from magical psionicists to cruel
                                Swarm Empires and tough Earth Federations. The hero pool of Spark
                                Era is huge and infinitely diverse. Unleash incredible abilities on
                                the road to victory.
                            </p>
                            {/* <div className={styles.guideBtn}>
                                <div className={styles.moreBtn}>
                                    LEARN MORE
                                    <p className={styles.textBtn}></p>
                                </div>
                            </div> */}
                        </div>
                        <div className={styles.contain}>
                            <div>INTERSTELLAR EXPLORATION GAMEFI</div>
                            <div>INTERSTELLAR MISSION</div>
                            <div>ETERNAL GALAXY</div>
                        </div>
                    </article>
                </section>

                <section className={styles.playStage}>
                    <p>BEGIN YOUR INTERSTELLAR JOURNEY HERE</p>
                    <div className={styles.contain}>
                        <Button
                            type="trap"
                            onClick={() => window.open('https://meta.firework.games/')}
                        >
                            <span>JOIN THE METAVERSE NOW</span>
                            {/* <span>Expected to go live on February 1, 2022</span> */}
                        </Button>
                    </div>
                </section>
                <section
                    style={{
                        marginTop: '20px',
                    }}
                    className={styles.partnerStage}
                >
                    <header>STRATEGIC PARTNER</header>
                    {/* <header>PARTNER</header> */}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '6vh',
                        }}
                    >
                        <a href="http://aws.amazon.com/what-is-cloud-computing" target="_blank">
                            <img
                                src={require('../../assets/imgs/powered-by-aws-white.png').default}
                                alt="Powered by AWS Cloud Computing"
                                width="200"
                            />
                        </a>
                        <a href="https://www.nvidia.com" target="_blank">
                            <img
                                src={require('../../assets/imgs/nvidia.png').default}
                                alt="NVIDIA"
                                width="200"
                            />
                        </a>
                    </div>
                </section>
                <section className={styles.partnerStage}>
                    <header>PARTNER</header>
                    <div className={styles.contain}>
                        {partnerLists.map((item, index) => (
                            <a
                                key={index}
                                style={{
                                    backgroundImage:
                                        'url(' +
                                        require(`../../assets/imgs/home/${item.image}`).default,

                                    backgroundSize: item.size || '80%',
                                }}
                                href={item.url}
                                target="_blank"
                            ></a>
                        ))}
                    </div>
                </section>

                <section className={styles.contactStage}>
                    <div className={styles.iconFields}>
                        <header>CONTACT-US</header>
                        <div className={styles.icon}>
                            {iconLists.map((item, index) => (
                                <a
                                    key={index}
                                    style={{
                                        backgroundPosition: item.position,
                                    }}
                                    href={item.url}
                                ></a>
                            ))}
                        </div>
                    </div>
                </section>
                <div className={styles.footer}>
                    <p
                        style={{
                            height: '1px',
                            width: '100%',
                            background: '#6F6F6F',
                            marginBottom: '20px',
                        }}
                    ></p>
                    {/* <a href="http://aws.amazon.com/what-is-cloud-computing">
                        <img
                            src="http://awsmedia.s3.amazonaws.com/AWS_logo_poweredby_black_127px.png"
                            alt="Powered by AWS Cloud Computing"
                        />
                    </a> */}
                    <div className={styles.contactStage1}>
                        <div>
                            <p style={{ width: '100%' }}>Firework Games Entertainment Limited</p>
                            <p style={{ width: '100%' }}>
                                Address：9/F, Amtel Building, 148 Des Voeux Road Central, Hong Kong
                            </p>
                        </div>

                        <div className={styles.logo}></div>
                    </div>
                </div>
                {/* <div className={styles.navBtn} onClick={() => setShowNav(!showNav)}>
					{showNav ? (
						<svg width="42" height="42" viewBox="0 0 32 32">
							<circle opacity=".07" cx="16" cy="16" r="16" fill="#fff"></circle>
							<g stroke="#C7C7C7" strokeWidth="2">
								<path d="M12.007 11.973l8.132 8.132M11.993 20.093l8.131-8.132"></path>
							</g>
						</svg>
					) : (
						<svg width="42" height="42" viewBox="0 0 32 32">
							<circle opacity=".07" cx="16" cy="16" r="16" fill="#fff"></circle>
							<path
								fill="#C7C7C7"
								d="M22 10v2H10v-2zM22 15v2H10v-2zM22 20v2H10v-2z"
							></path>
						</svg>
					)}
				</div> */}
                {/* <video className={styles.video} loop autoPlay muted>
					<source src={bgVideo} type="video/mp4" />
				</video>
				<div className={styles.context}>
					<h1 className={styles.title}>BECOME A BRAVE INTERSTELLAR EXPLORER</h1>
					<p className={styles.subTitle}>
						CHOOSE YOUR CAMP, FORM YOUR ARMY, AND GO TOGETHER
						<br />
						TO WIN INTERSTELLAR GLORY.
					</p>
					<div className={styles.videoBox} onClick={openModal}></div>
				</div> */}
                {/* <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
					<div className={styles.modalChoseBtn} onClick={closeModal}>
						<svg
							aria-hidden="true"
							focusable="false"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 576 512"
							alt="times-hexagon"
						>
							<path
								fill="#fff"
								d="M553.5 231.8c8.7 14.9 8.7 33.4 0 48.4l-112 192c-8.6 14.7-24.4 23.8-41.5 23.8H176c-17.1 0-32.9-9.1-41.5-23.8l-112-192c-8.7-14.9-8.7-33.4 0-48.4l112-192C143.1 25.1 158.9 16 176 16h224c17.1 0 32.9 9.1 41.5 23.8l112 192zM409.6 338c4.7-4.7 4.7-12.3 0-17l-65-65 65.1-65.1c4.7-4.7 4.7-12.3 0-17L370 134.4c-4.7-4.7-12.3-4.7-17 0l-65 65-65.1-65.1c-4.7-4.7-12.3-4.7-17 0L166.4 174c-4.7 4.7-4.7 12.3 0 17l65.1 65.1-65.1 65.1c-4.7 4.7-4.7 12.3 0 17l39.6 39.6c4.7 4.7 12.3 4.7 17 0l65.1-65.1 65.1 65.1c4.7 4.7 12.3 4.7 17 0l39.4-39.8z"
							></path>
						</svg>
					</div>
					<div className={styles.modalContent}>
						<iframe
							src="https://www.youtube.com/embed/O02rXqrB2DQ?autoplay=1"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						></iframe>
					</div>
				</Modal> */}
                <ToastContainer />
            </div>
        );
    }
}

export default Index;
