import React from 'react';
import { isMobile } from '../../utils';
import styles from './index.module.scss';

const Contract = () => {
    const iconLists = [
        {
            name: 'youtube',
            url: 'https://www.youtube.com/channel/UCqlMta2eYZjVXek6wQSt4Fg',
            position: '0 0',
        },
        // {
        //     name: 'fb',
        //     url: ':https://www.facebook.com/Spark-Era-105523978672884/',
        //     position: '-57px 0',
        // },
        // {
        //     name: 'ins',
        //     url: 'https://www.instagram.com/sparkera_official/',
        //     position: '-102px 0',
        // },
        { name: 'twitter', url: 'https://twitter.com/SparkeraGame', position: '-155px 0' },
        { name: 'tel', url: 'https://t.me/FireworkGames_group', position: '-206px 0' },
        // { name: 'in', url: '', position: '-266px 0' },
        { name: 'cic', url: 'https://fireworkgames.medium.com/', position: '-315px 0' },
        { name: 'mail', url: 'mailto:Official@Firework.games', position: '-394px 0' },
    ];
    return (
        <div className={styles.contactStage} style={{ width: isMobile() ? '100%' : '' }}>
            <div className={styles.iconFields}>
                <p>CONTACT-US</p>
                <div className={styles.icon}>
                    {iconLists.map((item, index) => (
                        <a
                            key={index}
                            style={{
                                backgroundPosition: item.position,
                            }}
                            href={item.url}
                        ></a>
                    ))}
                </div>
            </div>
            {/* <div className={styles.logo}></div> */}
        </div>
    );
};

export default Contract;
