import { getToken } from '../utils/auth';

export const formatDate = (timestamp = 0) => {
    if (timestamp) {
        const offset = new Date().getTimezoneOffset() * 60000;
        const date = new Date(timestamp * 1000 + offset).toLocaleString();
        return `UTC ${date}`;
    } else {
        return '-';
    }
};

export function loadScript(src) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;

    document.head.appendChild(script);
}

export function removeScript(src) {
    // var script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.src = src;
    // script.onload = callback;
    document.head.removeChild(src);
}

/**
 * 格式化数字（加逗号）
 * @param number 需要被格式化的数字
 * @returns number
 */
export function formatNumber(src) {
    return Number(src).toLocaleString();
}

export function devLog() {
    const isDev = process.env.NODE_ENV === 'development';
    isDev && console.log(...arguments);
}

// 校验是否为手机端
export function isMobile() {
    const ua = navigator.userAgent.toLowerCase();
    return /(iphone|ipod|android|ios|ipad|mobile|blackberry|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone)/i.test(
        ua,
    );
}

export function BrowserVersion() {
    var u = navigator.userAgent,
        app = navigator.appVersion;
    return {
        trident: u.indexOf('Trident') > -1,
        presto: u.indexOf('Presto') > -1,
        webKit: u.indexOf('AppleWebKit') > -1,
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,
        mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/),
        ios: !!u.match(/(i[^;]+;(U;)? CPU.+Mac OS X)/),
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
        iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1,
        iPad: u.indexOf('iPad') > -1,
        webApp: u.indexOf('Safari') == -1,
    };
}

export async function accountIsSameAsCurrentWallet() {
    // const account = localStorage.getItem('account');
    const token = getToken();
    const ssUserInfo = sessionStorage.getItem('userInfo');
    const account = token && ssUserInfo && JSON.parse(ssUserInfo).WalletAddress;
    if (!account) return false;

    const res = await window.ethereum.request({ method: 'eth_requestAccounts' });
    return res[0] === account;
}
