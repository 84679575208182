const newList = [
    {
        img: '/image/news/aritcleHome1.png',
        date: '11/01/2022',
        title: 'Notice on Beware of Scams about Private Placement …',
        content: `Firework Games is a decentralized self-developed launching
  platform of blockchain games,aims to provide feasible ways for
  gamers worldwide to participate in blockchain games,as well as
  developing a bridging inter…`,
        detailUrl: '/LatestNews/3',
    },
    {
        img: '/image/news/aritleHome2.png',
        date: '21/12/2021',
        title: 'Rising star! Review of Spark Era opening a new era …',
        content: `Spark Era is a massively multiplayer online role-playing game
        (MMORPG) independently developed by Firework Games and based on
        an interstellar background. Developed based on the block-chain
        technology…`,
        detailUrl: '/LatestNews/2',
    },
    {
        img: '/image/news/aritcleHome3.png',
        date: '08/12/2021',
        title: 'The First Polygon-Grant Global Hackathon Competition …',
        content: `Spark Era is a massively multiplayer online role-playing game
        (MMORPG) independently developed by Firework Games and based on
        interstellar backgrounds, in which players can enter a huge
        interstellar world and …`,
        detailUrl: '/LatestNews/1',
    },
    {
        img: '/image/news/aritcleHome4.png',
        date: '13/06/2021',
        title: 'Firework Games is a practitioner of an open …',
        content: `Firework Games is a decentralized self-developed launching
        platform of blockchain games,aims to provide feasible ways for
        gamers worldwide to participate in blockchain games,as well as
        developing a bridging inter…`,
        detailUrl: '/LatestNews/0',
    },
];

export default newList;
