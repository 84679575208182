import Cookies from 'js-cookie';

const TokenKey = process.env.NODE_ENV === 'production' ? 'sessionId' : 'test_sessionId';
const domain = '.firework.games';

export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, { domain });
}

export function removeToken() {
    return Cookies.remove(TokenKey, { domain });
}
