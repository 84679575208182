// import Link from "next/link";
import styles from './index.module.scss';
// import Button from '../Button';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
// import { initWallet } from '../../views/stake/service';
import { Menu, Dropdown, Modal } from 'antd';
// import { TwitterOutlined } from '@ant-design/icons';
import 'antd/es/menu/style/css';
import 'antd/es/dropdown/style/css';
import 'antd/es/modal/style/css';
// 黑暗模式
import 'antd/dist/antd.dark.css';
// 样式覆盖
import './antd.css';
// import Icon from '@ant-design/icons';
import svgTwitter from '../../assets/imgs/icons/Twitter.svg';
import svgTelegram from '../../assets/imgs/icons/Telegram.svg';
import svgDiscord from '../../assets/imgs/icons/Discord.svg';
import svgMedium from '../../assets/imgs/icons/Medium.svg';
const IconTwitter = () => <img src={svgTwitter} />;
const IconTelegram = () => <img src={svgTelegram} />;
const IconDiscord = () => <img src={svgDiscord} />;
const IconMedium = () => <img src={svgMedium} />;
import { getToken } from '../../utils/auth';
import axios from 'axios';
// import { devLog } from '../../utils';

const Nav = () => {
    const [showNav, setShowNav] = useState(false);
    const [WalletAddress, setWalletAddress] = useState(localStorage.getItem('Address'));

    // function tipToast() {
    //     return toast.warn('coming soon', {
    //         position: 'top-center',
    //         theme: 'dark',
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: false,
    //         draggable: true,
    //         progress: undefined,
    //     });
    // }
    const token = getToken();
    const [accountAddress, setAccountAddress] = useState('');

    // useEffect(() => {
    //     initWallet();
    // }, []);

    async function connectWallet() {
        if (window.ethereum) {
            const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });

            localStorage.setItem('Address', account);
            setWalletAddress(account);
        }
    }

    useEffect(() => {
        // console.log('useEffect', localStorage.getItem('account'));

        // console.log('token', token);

        // todo 登录用户的钱包与连接钱包不一致的提示
        // todo 需求：允许未登录用户访问返佣页面

        if (token && token === sessionStorage.getItem('token')) {
            const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
            if (userInfo.LoginType === 0) {
                // 邮箱账号登录后显示邮箱地址
                setAccountAddress(userInfo.UserName);
            } else {
                // 第三方账号登录后显示第三方账号昵称
                setAccountAddress(userInfo.UserWithThirdName);
            }
        } else if (token) {
            // 获取用户信息
            axios
                .get(process.env.REACT_APP_USER_NODEJS + `/sso/getUserInfo/`, {
                    headers: { authToken: token },
                })
                .then(response => {
                    console.log('getUserInfo response', response);
                    // const code = response.data.code || 200;
                    if (response.data.code === 401) {
                        // 用户登录信息失效
                        Modal.confirm({
                            title: 'Tips',
                            content:
                                'Your login status has expired, you can stay on this page or login again.',
                            okText: 'Re-login',
                            cancelText: 'Cancel',
                            onOk: () => {
                                location.href =
                                    process.env.REACT_APP_SSO_URL +
                                    `/login?redirect=${location.href}`;
                            },
                        });
                    } else if (response.data.code === 200) {
                        const userInfo = response.data.data;
                        sessionStorage.setItem('token', token);
                        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
                        if (userInfo.LoginType === 0) {
                            // 邮箱账号登录后显示邮箱地址
                            setAccountAddress(userInfo.UserName);
                        } else {
                            // 第三方账号登录后显示第三方账号昵称
                            setAccountAddress(userInfo.UserWithThirdName);
                        }
                    } else {
                        Modal.error({
                            title: 'Failed',
                            content: response.data.message,
                            okText: 'Close',
                        });
                        throw new Error(response.data.message);
                    }
                })
                .catch(error => {
                    console.log('getUserInfo error', error);
                });
        }

        // const account = localStorage.getItem('account');
        // const accountEmail = localStorage.getItem('accountEmail');
        // if (accountEmail) {
        //     setAccountAddress(accountEmail);
        // } else if (account) {
        //     const text = `${account.slice(0, 6)}...${account.slice(-6)}`;
        //     setAccountAddress(text);
        // }
    }, []);

    const history = useHistory();
    const jumpReg = () => {
        history.push({ pathname: '/register' });
    };
    const jumpLogin = () => {
        location.href = process.env.REACT_APP_SSO_URL + '/login?redirect=' + location.href;
    };
    const jumpLoginUrl = process.env.REACT_APP_SSO_URL + '/login?redirect=' + location.href;
    const jumpLogoutUrl = process.env.REACT_APP_SSO_URL + '/logout?redirect=' + location.href;

    const contactMenu = (
        <Menu
            items={[
                {
                    label: 'Twitter',
                    icon: <IconTwitter />,
                    onClick: () => {
                        window.open('https://twitter.com/SparkeraGame');
                    },
                },
                {
                    label: 'Telegram',
                    icon: <IconTelegram />,
                    onClick: () => {
                        window.open('https://t.me/FireworkGames_group');
                    },
                },
                {
                    label: 'Discord',
                    icon: <IconDiscord />,
                    onClick: () => {
                        window.open('https://discord.gg/erNDmxyUBz');
                    },
                },
                {
                    label: 'Medium',
                    icon: <IconMedium />,
                    onClick: () => {
                        window.open('https://fireworkgames.medium.com');
                    },
                },
            ]}
        />
    );
    const userMenu = (
        <Menu
            items={[
                {
                    label: 'Logout',
                    onClick: () => {
                        console.log('Logout');
                        // localStorage.removeItem('account');
                        // localStorage.removeItem('accountEmail');
                        // history.push({ pathname: '/' });
                        // location.reload()
                        sessionStorage.removeItem('userInfo');
                        window.location.href = jumpLogoutUrl;
                    },
                },
            ]}
        />
    );

    return (
        <header
            className={styles['spk-nav']}
            style={{
                background: window.location.pathname === '/TransitionWithVouchers' ? '#000' : '',
            }}
        >
            <div className={styles['spk-nav--left']}>
                <ul>
                    <li>
                        <a href="/" className={styles.logo} />
                    </li>
                    <li>
                        <a href="https://meta.firework.games/" target="_blank">
                            Metaverse
                        </a>
                        {/* <Link to="/">Metaverse</Link> */}
                    </li>
                    <li>
                        <a href="https://supply.firework.games/game" target="_blank">
                            Game
                        </a>
                    </li>
                    {/* <li>
                        <a onClick={() => tipToast()}>GALAXY GUIDE</a>
                    </li> */}
                    <li>
                        <a
                            href="https://supply.firework.games/interstellar-trader/hot"
                            target="_blank"
                        >
                            Interstellar Trader
                        </a>
                    </li>
                    <li>
                        <a href="https://market.firework.games" target="_blank">
                            Storage
                        </a>
                    </li>

                    <li>
                        {/* <a onClick={() => tipToast()}>GAME</a> */}
                        <Link to="/loyalty">Loyalty Program</Link>
                    </li>
                    <li>
                        {/* <a onClick={() => tipToast()}>GAME</a> */}
                        <Link to="/invite">Referral</Link>
                    </li>
                    <li>
                        {/* <a href="https://intro.firework.games/news">NEWS</a> */}
                        <Link to="/newsList">News</Link>
                    </li>
                    <li>
                        <a href="https://intro.firework.games/files/Firework_Games_Whitepaper_en.pdf">
                            Whitepaper
                        </a>
                    </li>
                </ul>
            </div>
            <div className={styles['spk-nav--right']}>
                {/* {accountAddress && <Button>{accountAddress}</Button>} */}
                <Dropdown overlay={contactMenu}>
                    <div className={styles['btn']}>Contact us</div>
                </Dropdown>
                <div className={styles['divider']}></div>
                {WalletAddress ? (
                    // <Dropdown overlay={userMenu}>
                    <div className={styles['btn']}>{`${WalletAddress.substring(
                        0,
                        6,
                    )}...${WalletAddress.substring(WalletAddress.length - 6)}`}</div>
                ) : (
                    // </Dropdown>
                    // <div className={styles['btn']} onClick={() => jumpReg()}>
                    //     Connect account
                    // </div>
                    <div className={styles['btn']} onClick={connectWallet}>
                        {' '}
                        Wallet{' '}
                    </div>
                )}
                <div className={styles['divider']}></div>

                {accountAddress && (
                    <Dropdown overlay={userMenu}>
                        <div className={styles['btn']}>{accountAddress}</div>
                    </Dropdown>
                )}
                {!accountAddress && (
                    // <div className={styles['btn']} onClick={() => jumpReg()}>
                    //     Connect account
                    // </div>
                    // <div className={styles['btn']} onClick={() => jumpLogin()}>
                    //     Login
                    // </div>
                    <a className={styles['btn']} href={jumpLoginUrl}>
                        Login
                    </a>
                )}
                {/* <Button onClick={() => tipToast()}>JOIN THE METAVERSE NOW</Button> */}
                <div className={styles.navBtn} onClick={() => setShowNav(!showNav)}>
                    {showNav ? (
                        <svg width="42" height="42" viewBox="0 0 32 32">
                            <circle opacity=".07" cx="16" cy="16" r="16" fill="#fff"></circle>
                            <g stroke="#C7C7C7" strokeWidth="2">
                                <path d="M12.007 11.973l8.132 8.132M11.993 20.093l8.131-8.132"></path>
                            </g>
                        </svg>
                    ) : (
                        <svg width="42" height="42" viewBox="0 0 32 32">
                            <circle opacity=".07" cx="16" cy="16" r="16" fill="#fff"></circle>
                            <path
                                fill="#C7C7C7"
                                d="M22 10v2H10v-2zM22 15v2H10v-2zM22 20v2H10v-2z"
                            ></path>
                        </svg>
                    )}
                </div>
                <div className={`${styles.footNav} ${showNav ? '' : styles.hidden}`}>
                    {/* <div className={styles.footNavItem}>
                        <a onClick={() => tipToast()}>METAVERSE</a>
                    </div>
                    <div className={styles.footNavItem}>
                        <a onClick={() => tipToast()}>GAME</a>
                    </div>
                    <div className={styles.footNavItem}>
                        <a onClick={() => tipToast()}>GALAXY GUIDE</a>
                    </div>
                    <div className={styles.footNavItem}>
                        <a href="https://market.firework.games/">MARKETPLACE</a>
                    </div>
                    <div className={styles.footNavItem}>
                        <Link to="/newsList">NEWS</Link>
                    </div>
                    <div className={styles.footNavItem}>
                        <a href="https://intro.firework.games/files/Firework_Games_Whitepaper_en.pdf">
                            WHITEPAPER
                        </a>
                    </div> */}

                    <div className={styles.footNavItem}>
                        <a href="https://meta.firework.games/" target="_blank">
                            METAVERSE
                        </a>
                        {/* <Link to="/">Metaverse</Link> */}
                    </div>
                    <div className={styles.footNavItem}>
                        <a href="https://betam.firework.games/game" target="_blank">
                            Game
                        </a>
                    </div>
                    <div className={styles.footNavItem}>
                        <a href="https://betam.firework.games/home" target="_blank">
                            Interstellar Trader
                        </a>
                    </div>
                    <div className={styles.footNavItem}>
                        <a href="https://betam.firework.games/marketplace/storage" target="_blank">
                            Storage
                        </a>
                    </div>
                    <div className={styles.footNavItem}>
                        {/* <a onClick={() => tipToast()}>GAME</a> */}
                        <Link to="/loyalty">Loyalty Program</Link>
                    </div>
                    <div className={styles.footNavItem}>
                        <Link to="/invite">Referral</Link>
                    </div>
                    {/* <div className={styles.footNavItem}>
                        <a onClick={() => tipToast()}>GALAXY GUIDE</a>
                    </div> */}
                    {/* <div className={styles.footNavItem}>
                        <a href="/beta-now">BETA DOWNLOAD</a>
                    </div> */}
                    {/* <div className={styles.footNavItem}>
                        <a href="https://market.firework.games" target="_blank">
                            MARKETPLACE
                        </a>
                    </div> */}

                    <div className={styles.footNavItem}>
                        {/* <a href="/newsList">NEWS</a> */}
                        <Link to="/newsList">News</Link>
                    </div>
                    <div className={styles.footNavItem}>
                        <a href="https://intro.firework.games/files/Firework_Games_Whitepaper_en.pdf">
                            Whitepaper
                        </a>
                    </div>
                </div>
            </div>
            {/* <ToastContainer /> */}
        </header>
    );
};

export default Nav;
