import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import './assets/iconfont/iconfont.css';
import { GoogleAnalyticsPageClick } from 'firework-analytics';

GoogleAnalyticsPageClick();

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://f0cd4571730a484b910af462c3b568f7@o1324597.ingest.sentry.io/6676576',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(<App />, document.getElementById('root'));
